@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@font-face {
  font-family: 'SourceSansProRegular';
  src: url('./assets/fonts/source-sans-pro/sourcesanspro-regular.ttf') format('truetype')
}

.goog-menuheader{
  color:white;
  font-weight: bold;
  margin-top:5px;
  padding-bottom: 4px;
  border-bottom: 1pt solid;
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.goog-tooltip{
  background-color: rgb(130,130,130);
  color: white;
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, sans-serif;
  font-size: 8pt;
  padding:7px;
  left:34px;
  border-radius:3px;
  z-index: 9999;
}

.goog-menuitem-content {
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 14px;
}

.goog-menuitem-icon {
  left: 12px !important;
  font-size: 20px;
  height: 20px !important;
  width: 20px !important;
}

.blocklyWidgetDiv:not(.fieldTextInput) {
  padding-bottom: 12px;
}

.blocklyWidgetDiv.fieldTextInput input.blocklyHtmlInput {
  font-size: 14pt !important;
}

.blocklyWidgetDiv .goog-menu, .custom-rest-output .goog-menu {
  background-color: #16191D !important;
  border: 1px solid #2C333A;
  border-radius: 6px;
  width: calc(100% - 2px);
  overflow-y: visible !important;
  padding: 8px 0 !important;
}

.blocklyWidgetDiv .goog-menuitem, .blocklyDropDownDiv .goog-menuitem {
  padding: 9px 12px 9px 28px;
  color: #E6EAF0 !important;
}

.blocklyWidgetDiv .goog-menuitem-highlight {
  background-color: #1D2125 !important;
  border-color: transparent !important;
  border-width: 0px !important;
}

.blocklyWidgetDiv .goog-menuitem-disabled .goog-menuitem-accel, .blocklyWidgetDiv .goog-menuitem-disabled .goog-menuitem-content, .blocklyDropDownDiv .goog-menuitem-disabled .goog-menuitem-accel, .blocklyDropDownDiv .goog-menuitem-disabled .goog-menuitem-content {
  color: #596573 !important;
}

body{
  width:100%;
  height:100%;
  position:absolute;
  background-color: white;
  box-sizing:border-box;
  overflow:hidden;
/*   padding:8px; */
  /* border:8pt solid white; */
}

.header{
  height:7%;
  width:100%;
  background-color: #3b4449;
  position:absolute;
  padding-left:20px;
  padding-right:20px;
  display:block;
  color:white;
  display:inline-block;
}

.header div{
  display:inline-block;
  top:50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position:relative;

}

.footer {
  top:93%;
  height:7%;
  width:100%;
  position:absolute;
  padding-right:20px;
  border-top:1pt solid rgb(237,240,241);
/*   background-color:rgb(67,77,83); */
  background: #3D464D;
}

.footer div{
  float:right;
  color:white;
  width:120px;
  text-align:center;
  border-radius:4px;
  top:50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position:relative;
  padding: 5px;
}

#cancelButton{
  cursor:pointer;
  background-color: transparent;
  color: white;
}

#saveButton{
  cursor:pointer;
  background-color:rgb(30,173,138);
}

.header > .buttons{
  padding:5px;
  float:right;
  color:white;
  cursor:pointer;
}

.content{
  padding:20px;
  position:absolute;
  top:7%;
  bottom:7%;
  width: 100%;
  left:0;
  right:0;
}

.displayNone{
  display:none;
}

.blocklyHtmlInput::placeholder{
  color:lightgrey;
}

.fieldDateEditor{
  width: 100%;
  height: 100%;
  display: flex;
}

.fieldDateEditor > input{
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.fieldDateEditor > div{
 padding: 0px;
  width:10%;
}

.fieldDateEditor > input::-webkit-inner-spin-button{
  display: none;
}

/*.fieldTextInput{*/
/*  opacity:0.5*/
/*}*/
/*.blocklyHtmlInput{*/
/*  opacity: 0.5;*/
/*}*/


/* bg color of workspace, etc.. */
.blocklySvg{
    background-color: #1D2125;
}

#blocklyDiv{
  border:1pt solid rgb(237,240,241);
  box-sizing:border-box;
  width:100%;
  height:100%;
}

/* Toolbox */
div#blocklyDiv .blocklyToolboxDiv {
    background-color: #464646;
    color:var(--font-color);
    padding-top: 25px;
}

.blocklyToolboxDiv{
  position: absolute !important;
  width: 200px;
  z-index: 100200;
}

.blocklyToolboxDelete ~ .blocklyFlyout > .blocklyFlyoutBackground{
  opacity: 0.5;
  transition: all 0.125s linear;
}

.blockly-panel {
  width:100%;
  height:100%;
  box-sizing:border-box;
  position:relative;
  display:inline-block;
}

.output-panel {
  position:relative;
  width:25%;
  height:100%;
  box-sizing:border-box;
  float:right;
}

.explanation-panel{
  height:49.5%;
  margin-bottom:1%;
  width:100%;
  padding:8px;
  position:relative;
  background-color:white;
  overflow-y:scroll;
  box-sizing:border-box;
}

#codeExplanationText{
  width:100%;
  height:90%;
/*   background-color:rgb(237,237,237) */
}

.output-panel #codeDiv{
  background-color:white;
/*   height:49.5%; @*/

  height:100%;
  width:100%;
/*   padding:8px; */
  box-sizing:border-box;
/*   border-top: 4px solid  rgb(237,237,237);; */
  display:block;
  position:relative;
  background-color:white;
/*   overflow-y:scroll; */
}

.rest-service-test {
  background-color: #16191D;
  display: flex;
  flex-flow: column;
  height: 100%;
  border-radius: 6px;
  color: #ffffff;

  textarea {
    outline: unset;
    border: unset;
  }

  button {
    display: inline-block;
    font-size: 11px;
    font-family: "sourceSansProSemibold", Arial, Helvetica, sans-serif;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: #ffffff;
    height: 26px;
    line-height: 10px;
    letter-spacing: 0.25px;
    border: 1px solid transparent;
    border-radius: 3px;
    min-width: 54px;
    padding: 6px 0px;
    text-transform: uppercase;
  }

  .responseUrl {
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
    padding: 5px;
  }
  
  .rest-close, .rest-copy {
    color: #8695A7;
    border: none;
    background-color: transparent;
    padding-right: 0px;
    margin-right: 5px;

    &:hover {
      color: #9FADBC;
    }
  }

  .rest-copy {
    float: right;
    display: flex;
    min-width: 65px;
    margin-left: 15px;
    margin-right: 0px;

    &.disabled {
      cursor: default;
      opacity: 1;
    }

    i {
      width: 16px;
      height: 16px;
      background: url(./assets/workspace/copy.svg) center center no-repeat;
      margin-right: 10px;
    }

    span {
      line-height: 16px;
    }
  }

  .rest-done {
    -webkit-text-fill-color: #E6EAF0;
    opacity: 1;
    color: #E6EAF0;
    background-color: #004ECC !important;

    &:hover:not(.disabled) {
      background-color: #0C5FE4 !important;
    }

    &.disabled {
      cursor: default;
      background-color: #596573 !important;
    }
  }

  .rest-queryurl {
    margin-left: 10px;
    -webkit-text-fill-color: #E6EAF0;
    opacity: 1;
    color: #E6EAF0;
    background-color: #004ECC !important;
    padding-left: 12px;
    padding-right: 12px;

    &:hover {
      background-color: #0C5FE4 !important;
    }
  }

  & > div {
    margin: 5px;
  }
  
  & > input {
    width: 25%;
    margin: 5px;
    background-color: rgb(30,173,138);
    color: #E6EAF0;
  }
  
  .responseArea {
    color: #E6EAF0;
    display: block;
    flex: 1 1 auto;
    background-color: #22262B;
    resize: none;
    overflow-y: scroll;
    overflow-x: unset;
    padding: 5px;
  }

  .responseArea::-webkit-scrollbar {
    width: 8px;
    cursor: pointer !important;
  }

  .responseArea::-webkit-scrollbar-thumb {
    background-color: rgb(69, 78, 89);
    border-radius: 8px;
    cursor: pointer !important;
  }

  .rest-control {
    //background-color: #2C2D2D;
    margin: unset;
    padding: 10px;
  }
}

/*render over top of widgetDiv (zindexd=999999)*/
.blocklyDropDownDiv{
  z-index: 100000;
}

.blocklyWidgetDiv, .custom-rest-output {
  // background-color:#464646;
  border-radius:5px;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, .3);
  width: 200px;
  overflow: hidden;
  transform: translate(5px, -5px);

  .goog-menu {
    overflow-y: scroll;
    background-color: #464646;
  }

  .goog-menuitem {
    color: #FFFFFF;
  }

  .goog-menuitem-highlight {
    background-color: #3E3E3E;
    border-color: #3E3E3E;
    border-style: solid;
  }
}

.custom-rest-output {
  top: 5px;
  left: unset;
  right: 18px;
  position: absolute;
  direction: ltr;
}

/* Blockly Tooltip */
.blocklyTooltipDiv {
  background-color: #16191D;
  border: 1px solid #2C333A;
  border-radius: 4px;
  color: #E6EAF0;
  padding: 7px 10px;
  opacity: 1 !important;
}

.dropdown-bar {
  display: block;
  font-family: sans-serif;
  font-size: 16px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

#languageDropdown {
  border: 0;
  background-color: transparent;
  font-size: 16px;
  outline: none;
}

#im-just-an-underline {
  border-bottom: 1px solid;
  display: inline;
  padding-right: 5px;
}

.POps {
  height: 1px;
  border: 0;
  background-color: rgba(0,0,0,.2);
}

.close-button {
  position: absolute;
  top: 24px;
  right: 24px;
  border-radius: 100%;
  color: white;
  width: 56px;
  height: 56px;
  line-height: 56px;
  box-shadow: 0 1px 4px rgba(0,0,0,.37);
  transition: box-shadow .3s ease-in-out;
  text-align: center;
  font-weight: 500;
  background-color: rgb(4,77,124);
  cursor: pointer;
}

.close-button:hover {
  box-shadow: 0 2px 2px rgba(0,0,0,.2),0 6px 10px rgba(0,0,0,.3);
  transition: box-shadow .3s ease-in-out;
}

.but-not-that-pretty {
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}


.center{
  text-align:center;
}


.indent{
  position:relative;
  border-left: 20px solid rgba(0,0,0,0.125);
  padding-left:5px;
}

.explanation{
  background-color:rgb(237,237,237);
  padding-left:5px;
  color:white;
}

.blocklyPath.blocklyBlockBackground {
  stroke-width: 1px;
}

.blocklySelected .blocklyPath.blocklyBlockBackground {
  stroke-width: 1.5px;
}

.blocklySelected .blocklyPath.blocklyBlockBackground:last-child {
  display: none;
}

.blocklyFlyout .blocklyZoom {
  display: none
}

.blocklyToolboxDiv {
  border-right: 1px solid #2C333A;
  border-left: 1px solid hsla(0, 0%, 0%, 0.15);
}

.blocklyTreeRow {
  text-align: left;
  height: 38px; // original designs were 44px, but its too tall
  box-sizing: border-box;
  cursor: pointer;
  border-left: none !important;
  margin: 0;
  padding: 5px 35px 5px 7px !important;
  color: #E6EAF0;
}

.blocklyTreeRow:not(.blocklyTreeSelected):hover {
  background-color: #22262B;
  color: #E6EAF0 !important;
}

.blocklyTreeSeparator {
  border-bottom: 1pt solid #2C333A;
  border-left: none !important;
  /*border-bottom: solid #e5e5e5 1px;*/
  height: 0;
  margin: 4px 10px;
}

.blocklyTreeIcon {
  display: inline-block !important;
  height: 1.5rem;
  width: 1.5rem;
  text-align: center;
  margin-left: 3px;
  background-size: 75%;
  background-repeat:  no-repeat;
  background-position: center;
  border-radius: 5px;
  line-height: 26px !important;
  text-align: center;
  font-size: 22px;
}

.blocklyTreeLabel {
  font-size: 0.8rem;
  margin-left: 10px;
  font-weight: 400;
  cursor: pointer;
  color: #FFFFFF;
  line-height: 1rem;
  font-family: 'SourceSansProRegular';
  font-size: 14px;
}

.blocklyFlyout {
  min-width: 195px;
  z-index: 100200;

  .blocklyFlyoutLabelText {
    fill: #FFF;
    font-family: 'SourceSansProRegular';
    font-size: 20pt;
    font-weight: inherit;
  }

  .blocklyFlyoutButton {
    fill: #004ECC;
    border: none;

    .blocklyFlyoutButtonBackground {
        stroke: #004ECC;
    }

    .blocklyText {
      fill: #E6EAF0;
      text-transform: uppercase;
    }

    &:hover {
      fill: #0C5FE4;
    }
  }
}

.blocklyText.field-text-placeholder {
  fill: #aaa;
  display: none;

  &.visible {
    display: block;
  }
}

.field-text-quote, .blocklyText {
  &.invisible {
    display: none;
  }
}

.blocklyFlyoutBackground {
  fill: #16191D;
  fill-opacity: 1;
  transform: scale(2);
}

.blocklyToolboxDiv {
  background-color: #16191D;
  margin-top: 48px;
  height: calc(100% - 48px) !important;

  .scrollbar-thumb {
    width: 4px;
    left: 2px;
  }

  .scroll-content {
    padding-bottom: 5px;
  }
}

.toolbox-trashcan {
  position: absolute;
  width: 46px;
  min-height: 100%;
  z-index: 48;
  background: rgba(22, 25, 29, 0.8);
  opacity: 0;
  display: none;
  transition: 0.2s ease-in-out background;
  text-align: center;

  &:before {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    font-size: 24px;
  }

  &.expanded {
    width: 200px;
  }

  &.forDelete {
    background: rgba(80,30,30, 0.8);
  }
}

.blocklyTreeRow {
  position: relative;
}

.blocklyTreeRowExpanded:after {
  background-repeat: no-repeat;
  //content: "";
  width: 15px;
  height: 12px;
  top: 12px;
  right: 5px;
  position: absolute;
  display: inline-block;
  background-image: url(https://img.icons8.com/ios-glyphs/452/forward.png);
  background-position: 0px 0px;
  background-size: 12px;
}

.blocklyTreeRow.blocklyTreeSelected {
  background-color: #1D2125 !important;
}

.blocklyTreeRow.blocklyTreeSelected .blocklyTreeLabel {
  color: #E6EAF0 !important;
}

.blocklyTreeRow .blocklyTreeRow .blocklyTreeIcon {
  display: none !important;
}

.blocklyTreeRow.blocklyTreeSelected:before {
  width: 3px;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background-color: #0C5FE4;
}

.blocklyTreeIcon.undefined {
  display: none !important;
}

.blocklyTreeIcon.category_variables {
  // TODO: is not used anywhere, consider removing
  background-image: url(./assets/toolbox/variables.svg);
}

.blocklyTreeIcon.category_functions {
  // TODO: is not used anywhere, consider removing
  background-image: url(./assets/toolbox/functions.svg);
}

.blocklyTreeIcon.subcategory{
  background:none;
  border-right: 2pt solid grey;
  border-radius: 0px;
  box-sizing: border-box;
}

svg.fa-angle-double-left {
  position: absolute;
  bottom: 20px;
  left: 170px;
  z-index: 999;
  color: #AAA;
  cursor: pointer;
}

svg.fa-angle-double-right {
  position: absolute;
  bottom: 20px;
  left: 13px;
  z-index: 999;
  color: #AAA;
  cursor: pointer;
}


.blocklyWorkspace {
  .blocklyTrash {
    display: none;
  }
  /*width: calc(100% - 240px);*/
  /*height: calc(100% - 50px);*/
  /*transform: translate(185px, 25px);*/

  .blocklyZoom {
    .editor-icon.icon-background {
      height: 142;
      y: 44;
    }
  }
}

#workspaceMountDiv > .injectionDiv > svg > .blocklyWorkspace {
  .blocklyZoom {
    // hide all the navigation icons defined by blockly
    // our custom icons will be created instead
    display: none !important;
  }
}

.blocklyText, .blocklyHtmlInput {
  font-family: 'SourceSansProRegular';
  font-size: 14pt;
  font-weight: inherit;
}

.goog-menuheader, .goog-menuitem-content {
  font-family: 'SourceSansProRegular';
  font-size: 14px;
  font-weight: 400;
}

.blocklyDatePicker {
  .goog-date-picker-monthyear,
  .goog-date-picker-btn,
  .goog-date-picker-wday,
  .goog-date-picker-date {
    font-family: 'SourceSansProRegular';
    font-size: 14px;
    font-weight: 400;
  }
}

.blocklyWidgetDiv.fieldTextInput {
  line-height: 30px;
}
