.ErrorMessage{
    position:absolute;
    color:white;
    z-index: 41; /* in front of toolbox */
    margin:0 35%;
    width:30%;
    border-radius: 5px;
    top:0%;
    display: none;
    text-align: center;
    padding:5px;
    transition: all 1s ease;
    box-shadow: -2px 2px 2px lightgrey;

}

.ErrorMessage button{
    border-radius: 5px;
    border:none;
    padding:5px;
    margin:5px;
}

.enter{
    opacity: 0;
    display:block;
    transform:translateY(-100%);
}

.enter-done{
    transition: all 0.5s ease;
    top:5%;
    background-color: rgb(130,130,130);
    display:block;
}

.exit{
    transition: all 0.5s ease;
    top:5%;
    background-color: rgb(130,130,130);
    display:block;
}

.exit-done{
    opacity: 0;
    top:0%;
    display:block;
    transform:translateY(-100%);
    transition: all 0.5s ease;
}