@font-face {
    font-family: 'SourceSansProSemibold';
    src: url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.eot');
    src: url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.eot') format('embedded-opentype'),
    url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.woff') format('woff'),
    url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.ttf') format('truetype'),
    url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.svg#SourceSansProSemibold') format('svg');
}

#delete-block-dialog-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(79, 79, 79, 0.9);
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

#delete-block-dialog-wrapper {
    width: 600px;
    height: 300px;
    border: 4px solid #2C2D2D;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.5rem;
}

.delete-block-dialog-header {
    position: relative;
    background: #2C2D2D;
    color: white;
    text-align: center;
    height: 52px;
}

.delete-block-dialog-header h4 {
    font-family: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;
    font-size: 20px;
    margin-top: 0;
    line-height: 50px;
    text-align: left;
    padding-left: 1em;
}

.delete-block-dialog-body {
    flex-grow: 1;
    justify-content: space-between;
    background: #2C2D2D;
    border-top: 1px solid #444444;
    padding: 20px;
    font-size: 1.1em;
    color: white;
}

.delete-block-dialog-footer {
    background: #2C2D2D;
    min-height: 60px;
}

.delete-block-dialog-footer .btn-cancel,
.delete-block-dialog-footer .btn-continue {
    background-color: #004ECC;
    font-family: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;
    border: none;
    color: #E6EAF0;
    cursor: pointer;
    outline: none;
    padding: 5px;
    margin: 12px 18px;
    border-radius: 0.25rem;
    float: right;
    text-transform: uppercase;
    font-size: 14px;
    width: 150px;
    height: 36px;
    letter-spacing: 0.25px;
}

.delete-block-dialog-footer .btn-cancel {
    color: #8695A7;
    border: 1px solid hsla(0, 0%, 0%, 0.15);
    background-color: unset;
    float: right;
    margin-right: 1rem;
    width: 84px;
}

.delete-block-dialog-footer .btn-cancel:hover {
    color: #9FADBC;
}

.delete-block-dialog-footer .btn-continue:hover {
    background-color: #0C5FE4;
}
