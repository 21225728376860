@font-face {
  font-family: 'SourceSansProSemibold';
  src: url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.eot');
  src: url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.eot') format('embedded-opentype'),
  url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.woff') format('woff'),
  url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.ttf') format('truetype'),
  url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.svg#SourceSansProSemibold') format('svg');
}

$button-background: #1EAD8A;
$button-hover-background: #0B705D;
$button-font: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;

.modal-buttons-container {
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  background: white;
  height: 36px;

  .container-left {
    margin-left: 20px;
    flex: 1 1 0px;
  }

  .container-right {
    margin-right: 20px;
  }

  .text-button {
    background: $button-background;
    color: white;
    border: 0px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 22px;
    font-family: $button-font;
    display: inline;
    padding: 5px;
    width: 84px;
    height: 36px;
    border-radius: 3px;
    text-align: center;

    &:hover {
      background: $button-hover-background;
    }

    &-cancel {
      color: black;
      background: white;
      width: 60px;
      padding: 5px;

      &:hover {
        background: white;
      }
    }

    &:focus {
      outline: none;
    }
  }

  a {
    text-decoration: none;

    &:first-of-type {
      margin-right: 30px;
    }
  }
}