body,
#root,
.content-container,
.workspace,
#workspaceMountDiv {
  height: 100%;
  width: 100%;
  position: absolute;
}

.scrollable {
  overflow: scroll;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.hv-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Blockly Custom HTML Blocks */
.customHtmlInputContainer {
  display: block;
  /* top: 175.55px; */
  /* direction: ltr; */
  width: 100%;
  height: calc(100% - 10px);
  transform: scale(0.9);
  border-radius: 4.5px;
  border-color: rgb(255, 51, 85);
  left: 406.705px;
  transition: box-shadow 0.25s ease 0s;
  box-shadow: rgba(255, 255, 255, 0.3) 0px 0px 0px 4px;
  margin: 5px 0;
}

.customHtmlInput {
  border: none;
  font-family: inherit;
  height: 100%;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  color: #575E75;
  font-weight: 500;
  border-radius: 4.5px;
  transition: font-size 0.25s ease 0s;
  font-size: 16pt;
}

.customHtmlInputRemove {
  mask: url(../public/images/trash-open.svg);
  position: absolute;
  background: #FF661A;
  -webkit-mask-repeat: no-repeat;
  width: 28px;
  height: 28px;
  top: -50px;
  left: 50%;
  margin-left: -12px;
  cursor: pointer;
  z-index: 1232321232321;
}

.blocklyDraggable foreignObject {
  overflow: visible;
}

.blocklyTreeRoot {
  padding: 0px !important;
}

.blocklyTreeRoot div[aria-level='2'] .blocklyTreeLabel {
  padding-left: 15px;
}

.blocklyTreeRow::after {
  transition: transform 0.15s ease;
  transform: rotate(0deg);
}

.blocklyTreeRoot div[aria-expanded=true] > .blocklyTreeRow::after {
  transform: rotate(90deg);
}
.blocklyTreeIcon.inner-category {
  margin-left: 20px;
}
.blocklyTreeIcon.inner-category::after {
  background: #151e25;
  height: 1.4rem;
  width: 1.4rem;
  font-size: 10px;
  color: white;
  border-radius: 50%;
  content: attr(data-initials);
  display: block;
}

.bootstrap-override {
  @import "~bootstrap/scss/bootstrap";
}

.beta {
  height: 14px;
  width: 34px;
  background-color: orange;
  margin-left: 20px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: inline-block;
  margin-top: 3px;

  &::before {
    content: '';
    height: 0px;
    width: 0px;
    background: transparent;
    position: absolute;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid orange;
    margin-left: -7px;
  }

  &::after {
    content: 'BETA';
    margin-left: 6px;
    line-height: 0;
    font-size: 9px;
    font-weight: bold;
    position: absolute;
    margin-top: 6px;
    color: rgb(21, 22, 25);
  }
}

