.blocklyEditableText[data-argument-type="date"] text,
g[data-argument-type="date"] text {
    fill: #FFFFFF;
}

.blocklyDatePicker .goog-date-picker-date.goog-date-picker-today {
    color: #03dac6 !important;
}

.blocklyDatePicker .goog-date-picker-date.goog-date-picker-selected {
    background: #03dac6 !important;
    color: white !important;
}
