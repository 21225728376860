.action-buttons-container {
  position: absolute;
  right: 32px;
  width: 48px;
  height: auto;
  padding: 0px 12px 12px;
  box-sizing: border-box;
  background-color: #16191D;
  color: #E6EAF0;
  border: 1px solid #2C333A;
  box-shadow: 4px 4px 20px 1px rgba(0, 0, 0, .15);
  border-radius: 4px;
  user-select: none;

  .icon {
    height: 24px;
    width: 24px;
    display: block;
    margin-top: 12px;
    font-size: 22px;
    cursor: pointer;
    text-align: center;
    user-select: none;

    &.disabled {
      cursor: default;
      opacity: 0.3;
      filter: alpha(opacity = 30);
    }
  }
}

.tooltip {
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, sans-serif;
  font-size: 8pt;
  position: absolute;
  background-color: #16191D; 
  color: #E6EAF0; 
  border: 1px solid #2C333A;
  padding: 7px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000; 
  margin-right: 15px;
  box-shadow: 4px 4px 20px 1px rgba(0, 0, 0, .15);
  
  &::before {
    content: '';
    height: 8px;
    width: 8px;
    background: #16191D;
    position: absolute;
    right: 0px;
    top: 10px;
    border-top: 1px solid #2C333A;
    border-left: 1px solid #2C333A;
    margin-right: -5px;
    transform: rotate(135deg);
  }
}

.action-buttons-separator {
  width: 24px;
  height: 1px;
  background: #2C333A;
  margin: 8px 0px;
  display: block;

  & + span {
    margin-top: 0px !important;
  }
}
