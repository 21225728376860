.modal-header.small {
    position: relative;
    display: block;
    min-height: 50px;
    max-height: 50px;
    background: #16191D;
    color: #E6EAF0;
    text-align: left;
    padding-top: 6px;
    padding-left: 35px;
}