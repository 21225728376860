@font-face {
  font-family: "darktheme";
  src: url('./assets/fonts/darkthemefont/snap-dark.eot');
  src: url('./assets/fonts/darkthemefont/snap-dark.ttf') format("truetype"), url('./assets/fonts/darkthemefont/snap-dark.woff') format("woff"), url('./assets/fonts/darkthemefont/snap-dark.svg') format("svg");
  font-weight: normal;
  font-style: normal;
}

.darkTheme {
  [class^="snap-icon-"],
  [class*=" snap-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "darktheme", sans-serif;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .snap-icon-action:before {
    content: "\e900";
    color: #e6eaf0;
  }
  .snap-icon-action_dual .path1:before {
    content: "\e901";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-action_dual .path2:before {
    content: "\e902";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-action_dual .path3:before {
    content: "\e903";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-activity:before {
    content: "\e904";
    color: #e6eaf0;
  }
  .snap-icon-activity_dual .path1:before {
    content: "\e905";
    color: rgb(230, 234, 240);
  }
  .snap-icon-activity_dual .path2:before {
    content: "\e906";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-activity_dual .path3:before {
    content: "\e907";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-activity_dual .path4:before {
    content: "\e908";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-add:before {
    content: "\e909";
    color: #e6eaf0;
  }
  .snap-icon-add_dual .path1:before {
    content: "\e90a";
    color: rgb(230, 234, 240);
  }
  .snap-icon-add_dual .path2:before {
    content: "\e90b";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-agex:before {
    content: "\e90c";
    color: #e6eaf0;
  }
  .snap-icon-agex_dual .path1:before {
    content: "\e90d";
    color: rgb(230, 234, 240);
  }
  .snap-icon-agex_dual .path2:before {
    content: "\e90e";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-agex_dual .path3:before {
    content: "\e90f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-AI:before {
    content: "\e910";
    color: #e6eaf0;
  }
  .snap-icon-AI_dual .path1:before {
    content: "\e911";
    color: rgb(230, 234, 240);
  }
  .snap-icon-AI_dual .path2:before {
    content: "\e912";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-AI_dual .path3:before {
    content: "\e913";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-AI_dual .path4:before {
    content: "\e914";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-AI_dual .path5:before {
    content: "\e915";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-AI_dual .path6:before {
    content: "\e916";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-AI_dual .path7:before {
    content: "\e917";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-AI_dual .path8:before {
    content: "\e918";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-alert_active .path1:before {
    content: "\e919";
    color: rgb(230, 234, 240);
  }
  .snap-icon-alert_active .path2:before {
    content: "\e91a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-alert_active .path3:before {
    content: "\e91b";
    margin-left: -1em;
    color: rgb(232, 122, 102);
  }
  .snap-icon-alert_active_dual .path1:before {
    content: "\e91c";
    color: rgb(230, 234, 240);
  }
  .snap-icon-alert_active_dual .path2:before {
    content: "\e91d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-alert_active_dual .path3:before {
    content: "\e91e";
    margin-left: -1em;
    color: rgb(232, 122, 102);
  }
  .snap-icon-alert_active_dual .path4:before {
    content: "\e91f";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-alert_default:before {
    content: "\e920";
    color: #e6eaf0;
  }
  .snap-icon-alert_default_dual .path1:before {
    content: "\e921";
    color: rgb(230, 234, 240);
  }
  .snap-icon-alert_default_dual .path2:before {
    content: "\e922";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-alert_default_dual .path3:before {
    content: "\e923";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-announcement .path1:before {
    content: "\e924";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-announcement .path2:before {
    content: "\e925";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-announcement_dual .path1:before {
    content: "\e926";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-announcement_dual .path2:before {
    content: "\e927";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-announcement_dual .path3:before {
    content: "\e928";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-Api:before {
    content: "\e929";
    color: #e6eaf0;
  }
  .snap-icon-Api_dual .path1:before {
    content: "\e92a";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-Api_dual .path2:before {
    content: "\e92b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-Api_dual .path3:before {
    content: "\e92c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-app_action .path1:before {
    content: "\e92d";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-app_action .path2:before {
    content: "\e92e";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_action .path3:before {
    content: "\e92f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_action .path4:before {
    content: "\e930";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_action .path5:before {
    content: "\e931";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_action .path6:before {
    content: "\e932";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_action .path7:before {
    content: "\e933";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_action_dual .path1:before {
    content: "\e934";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-app_action_dual .path2:before {
    content: "\e935";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_action_dual .path3:before {
    content: "\e936";
    margin-left: -1.0419921875em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-app_action_dual .path4:before {
    content: "\e937";
    margin-left: -1.0419921875em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-app_action_dual .path5:before {
    content: "\e938";
    margin-left: -1.0419921875em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-app_action_dual .path6:before {
    content: "\e939";
    margin-left: -1.0419921875em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-app_action_dual .path7:before {
    content: "\e93a";
    margin-left: -1.0419921875em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-app_elements .path1:before {
    content: "\e93b";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-app_elements .path2:before {
    content: "\e93c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_elements .path3:before {
    content: "\e93d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_elements .path4:before {
    content: "\e93e";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_elements .path5:before {
    content: "\e93f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_elements .path6:before {
    content: "\e940";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_elements .path7:before {
    content: "\e941";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_elements_dual .path1:before {
    content: "\e942";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-app_elements_dual .path2:before {
    content: "\e943";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_elements_dual .path3:before {
    content: "\e944";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-app_elements_dual .path4:before {
    content: "\e945";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-app_elements_dual .path5:before {
    content: "\e946";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-app_elements_dual .path6:before {
    content: "\e947";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-app_elements_dual .path7:before {
    content: "\e948";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-app_empty:before {
    content: "\e949";
    color: #e6eaf0;
  }
  .snap-icon-App_label .path1:before {
    content: "\e94a";
    color: rgb(245, 124, 0);
  }
  .snap-icon-App_label .path2:before {
    content: "\e94b";
    margin-left: -1em;
    color: rgb(250, 251, 252);
  }
  .snap-icon-app_logo:before {
    content: "\e94c";
  }
  .snap-icon-applications:before {
    content: "\e94d";
    color: #e6eaf0;
  }
  .snap-icon-applications_dual .path1:before {
    content: "\e94e";
    color: rgb(230, 234, 240);
  }
  .snap-icon-applications_dual .path2:before {
    content: "\e94f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-applications_dual .path3:before {
    content: "\e950";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-applications_dual .path4:before {
    content: "\e951";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-applications_dual .path5:before {
    content: "\e952";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-arrow_left:before {
    content: "\e953";
    color: #e6eaf0;
  }
  .snap-icon-arrow_option_circle:before {
    content: "\e954";
    color: #e6eaf0;
  }
  .snap-icon-arrow_option_square:before {
    content: "\e955";
    color: #e6eaf0;
  }
  .snap-icon-arrow_option_vertical:before {
    content: "\e956";
    color: #e6eaf0;
  }
  .snap-icon-arrow_right:before {
    content: "\e957";
    color: #e6eaf0;
  }
  .snap-icon-audio:before {
    content: "\e958";
    color: #e6eaf0;
  }
  .snap-icon-audio_dual .path1:before {
    content: "\e959";
    color: rgb(230, 234, 240);
  }
  .snap-icon-audio_dual .path2:before {
    content: "\e95a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-audio_dual .path3:before {
    content: "\e95b";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-autogenerate_form:before {
    content: "\e95c";
    color: #e6eaf0;
  }
  .snap-icon-autogenerate_form_dual .path1:before {
    content: "\e95d";
    color: rgb(230, 234, 240);
  }
  .snap-icon-autogenerate_form_dual .path2:before {
    content: "\e95e";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-autogenerate_form_dual .path3:before {
    content: "\e95f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-autogenerate_form_dual .path4:before {
    content: "\e960";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-azure:before {
    content: "\e961";
    color: #e6eaf0;
  }
  .snap-icon-azure_dual:before {
    content: "\e962";
    color: #3884ff;
  }
  .snap-icon-barcode:before {
    content: "\e963";
    color: #e6eaf0;
  }
  .snap-icon-barcode_dual .path1:before {
    content: "\e964";
    color: rgb(230, 234, 240);
  }
  .snap-icon-barcode_dual .path2:before {
    content: "\e965";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-body:before {
    content: "\e966";
    color: #e6eaf0;
  }
  .snap-icon-body_dual .path1:before {
    content: "\e967";
    color: rgb(230, 234, 240);
  }
  .snap-icon-body_dual .path2:before {
    content: "\e968";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-border_bottom:before {
    content: "\e969";
    color: #e6eaf0;
  }
  .snap-icon-border_center:before {
    content: "\e96a";
    color: #e6eaf0;
  }
  .snap-icon-border_dash1:before {
    content: "\e96b";
    color: #e6eaf0;
  }
  .snap-icon-border_dash2:before {
    content: "\e96c";
    color: #e6eaf0;
  }
  .snap-icon-border_full:before {
    content: "\e96d";
    color: #e6eaf0;
  }
  .snap-icon-border_left:before {
    content: "\e96e";
    color: #e6eaf0;
  }
  .snap-icon-border_right:before {
    content: "\e96f";
    color: #e6eaf0;
  }
  .snap-icon-border_top:before {
    content: "\e970";
    color: #e6eaf0;
  }
  .snap-icon-business_card:before {
    content: "\e971";
    color: #e6eaf0;
  }
  .snap-icon-business_card_dual .path1:before {
    content: "\e972";
    color: rgb(230, 234, 240);
  }
  .snap-icon-business_card_dual .path2:before {
    content: "\e973";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-business_card_dual .path3:before {
    content: "\e974";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-business_card_dual .path4:before {
    content: "\e975";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-business_card_dual .path5:before {
    content: "\e976";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-business_card_dual .path6:before {
    content: "\e977";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-business_card_dual .path7:before {
    content: "\e978";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-button:before {
    content: "\e979";
    color: #e6eaf0;
  }
  .snap-icon-button_dual .path1:before {
    content: "\e97a";
    color: rgb(230, 234, 240);
  }
  .snap-icon-button_dual .path2:before {
    content: "\e97b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-button_dual .path3:before {
    content: "\e97c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-calendar:before {
    content: "\e97d";
    color: #e6eaf0;
  }
  .snap-icon-camera:before {
    content: "\e97e";
    color: #e6eaf0;
  }
  .snap-icon-camera_dual .path1:before {
    content: "\e97f";
    color: rgb(230, 234, 240);
  }
  .snap-icon-camera_dual .path2:before {
    content: "\e980";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-camera_dual .path3:before {
    content: "\e981";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-carousel:before {
    content: "\e982";
    color: #e6eaf0;
  }
  .snap-icon-carousel_dual .path1:before {
    content: "\e983";
    color: rgb(230, 234, 240);
  }
  .snap-icon-carousel_dual .path2:before {
    content: "\e984";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-carousel_dual .path3:before {
    content: "\e985";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-carousel_dual .path4:before {
    content: "\e986";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-certificate:before {
    content: "\e987";
    color: #e6eaf0;
  }
  .snap-icon-certificate_dual .path1:before {
    content: "\e988";
    color: rgb(230, 234, 240);
  }
  .snap-icon-certificate_dual .path2:before {
    content: "\e989";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-certificate_dual .path3:before {
    content: "\e98a";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-chart:before {
    content: "\e98b";
    color: #e6eaf0;
  }
  .snap-icon-chart_dual .path1:before {
    content: "\e98c";
    color: rgb(230, 234, 240);
  }
  .snap-icon-chart_dual .path2:before {
    content: "\e98d";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-chart_loading:before {
    content: "\e98e";
    color: #9fadbc;
  }
  .snap-icon-check:before {
    content: "\e98f";
    color: #e6eaf0;
  }
  .snap-icon-checkbox:before {
    content: "\e990";
    color: #e6eaf0;
  }
  .snap-icon-checkbox_checked:before {
    content: "\e991";
    color: #e6eaf0;
  }
  .snap-icon-checkbox_checked_dual .path1:before {
    content: "\e992";
    color: rgb(230, 234, 240);
  }
  .snap-icon-checkbox_checked_dual .path2:before {
    content: "\e993";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-checkbox_indeterminate:before {
    content: "\e994";
    color: #e6eaf0;
  }
  .snap-icon-checkbox_indeterminate_dual .path1:before {
    content: "\e995";
    color: rgb(56, 132, 255);
  }
  .snap-icon-checkbox_indeterminate_dual .path2:before {
    content: "\e996";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-checkbox_selected_blue:before {
    content: "\e997";
    color: #3884ff;
  }
  .snap-icon-checked:before {
    content: "\e998";
    color: #e6eaf0;
  }
  .snap-icon-checkmark_filled:before {
    content: "\e999";
    color: #e6eaf0;
  }
  .snap-icon-checkmark_outline:before {
    content: "\e99a";
    color: #e6eaf0;
  }
  .snap-icon-checkmark_outline_dual .path1:before {
    content: "\e99b";
    color: rgb(56, 132, 255);
  }
  .snap-icon-checkmark_outline_dual .path2:before {
    content: "\e99c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-chevron_down:before {
    content: "\e99d";
    color: #e6eaf0;
  }
  .snap-icon-chevron_right:before {
    content: "\e99e";
    color: #e6eaf0;
  }
  .snap-icon-chevron_sort:before {
    content: "\e99f";
    color: #e6eaf0;
  }
  .snap-icon-chevron_sort_dual .path1:before {
    content: "\e9a0";
    color: rgb(56, 132, 255);
  }
  .snap-icon-chevron_sort_dual .path2:before {
    content: "\e9a1";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-chevron_up:before {
    content: "\e9a2";
    color: #e6eaf0;
  }
  .snap-icon-choose_item_left:before {
    content: "\e9a3";
    color: #e6eaf0;
  }
  .snap-icon-choose_item_right:before {
    content: "\e9a4";
    color: #e6eaf0;
  }
  .snap-icon-clean:before {
    content: "\e9a5";
    color: #e6eaf0;
  }
  .snap-icon-clean_dual .path1:before {
    content: "\e9a6";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-clean_dual .path2:before {
    content: "\e9a7";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-clean_dual .path3:before {
    content: "\e9a8";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-clean_dual .path4:before {
    content: "\e9a9";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-clean_dual .path5:before {
    content: "\e9aa";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-close:before {
    content: "\e9ab";
    color: #e6eaf0;
  }
  .snap-icon-close_filled .path1:before {
    content: "\e9ac";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-close_filled .path2:before {
    content: "\e9ad";
    margin-left: -1em;
    color: rgb(224, 102, 81);
  }
  .snap-icon-close_outline:before {
    content: "\e9ae";
    color: #e6eaf0;
  }
  .snap-icon-close_outline_color .path1:before {
    content: "\e9af";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-close_outline_color .path2:before {
    content: "\e9b0";
    margin-left: -1em;
    color: rgb(224, 102, 81);
  }
  .snap-icon-close_outline_color .path3:before {
    content: "\e9b1";
    margin-left: -1em;
    color: rgb(224, 102, 81);
  }
  .snap-icon-close_outline_dual .path1:before {
    content: "\e9b2";
    color: rgb(230, 234, 240);
  }
  .snap-icon-close_outline_dual .path2:before {
    content: "\e9b3";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-cloud_connect .path1:before {
    content: "\e9b4";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-cloud_connect .path2:before {
    content: "\e9b5";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-cloud_connect .path3:before {
    content: "\e9b6";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-cloud_connect_dual .path1:before {
    content: "\e9b7";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-cloud_connect_dual .path2:before {
    content: "\e9b8";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-cloud_connect_dual .path3:before {
    content: "\e9b9";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-code_block .path1:before {
    content: "\e9ba";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-code_block .path2:before {
    content: "\e9bb";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-code_block .path3:before {
    content: "\e9bc";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-code_block .path4:before {
    content: "\e9bd";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-code_block .path5:before {
    content: "\e9be";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-code_block .path6:before {
    content: "\e9bf";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-code_block_dual .path1:before {
    content: "\e9c0";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-code_block_dual .path2:before {
    content: "\e9c1";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-code_block_dual .path3:before {
    content: "\e9c2";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-code_block_dual .path4:before {
    content: "\e9c3";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-code_block_dual .path5:before {
    content: "\e9c4";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-code_block_dual .path6:before {
    content: "\e9c5";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-cog:before {
    content: "\e9c6";
    color: #e6eaf0;
  }
  .snap-icon-cog_dual .path1:before {
    content: "\e9c7";
    color: rgb(230, 234, 240);
  }
  .snap-icon-cog_dual .path2:before {
    content: "\e9c8";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-cog_dual .path3:before {
    content: "\e9c9";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-color_blank .path1:before {
    content: "\e9ca";
    color: rgb(22, 25, 29);
  }
  .snap-icon-color_blank .path2:before {
    content: "\e9cb";
    margin-left: -1em;
    color: rgb(29, 33, 37);
  }
  .snap-icon-color_blank .path3:before {
    content: "\e9cc";
    margin-left: -1em;
    color: rgb(29, 33, 37);
  }
  .snap-icon-color_blank .path4:before {
    content: "\e9cd";
    margin-left: -1em;
    color: rgb(29, 33, 37);
  }
  .snap-icon-color_blank .path5:before {
    content: "\e9ce";
    margin-left: -1em;
    color: rgb(29, 33, 37);
  }
  .snap-icon-color_blank .path6:before {
    content: "\e9cf";
    margin-left: -1em;
    color: rgb(29, 33, 37);
  }
  .snap-icon-color_blank .path7:before {
    content: "\e9d0";
    margin-left: -1em;
    color: rgb(29, 33, 37);
  }
  .snap-icon-color_blank .path8:before {
    content: "\e9d1";
    margin-left: -1em;
    color: rgb(29, 33, 37);
  }
  .snap-icon-color_blank .path9:before {
    content: "\e9d2";
    margin-left: -1em;
    color: rgb(29, 33, 37);
  }
  .snap-icon-color_blank .path10:before {
    content: "\e9d3";
    margin-left: -1em;
    color: rgb(29, 33, 37);
  }
  .snap-icon-color_blank .path11:before {
    content: "\e9d4";
    margin-left: -1em;
    color: rgb(29, 33, 37);
  }
  .snap-icon-color_blank .path12:before {
    content: "\e9d5";
    margin-left: -1em;
    color: rgb(29, 33, 37);
  }
  .snap-icon-color_blank .path13:before {
    content: "\e9d6";
    margin-left: -1em;
    color: rgb(29, 33, 37);
  }
  .snap-icon-color_blank .path14:before {
    content: "\e9d7";
    margin-left: -1em;
    color: rgb(29, 33, 37);
  }
  .snap-icon-color_fill:before {
    content: "\e9d8";
    color: #ff5959;
  }
  .snap-icon-computer .path1:before {
    content: "\e9d9";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-computer .path2:before {
    content: "\e9da";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-computer_dual .path1:before {
    content: "\e9db";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-computer_dual .path2:before {
    content: "\e9dc";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-computer_dual .path3:before {
    content: "\e9dd";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-condition:before {
    content: "\e9de";
    color: #e6eaf0;
  }
  .snap-icon-condition_dual .path1:before {
    content: "\e9df";
    color: rgb(230, 234, 240);
  }
  .snap-icon-condition_dual .path2:before {
    content: "\e9e0";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-condition_dual .path3:before {
    content: "\e9e1";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-condition_dual .path4:before {
    content: "\e9e2";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-connect_filled .path1:before {
    content: "\e9e3";
    color: rgb(0, 174, 0);
  }
  .snap-icon-connect_filled .path2:before {
    content: "\e9e4";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .snap-icon-connect_filled .path3:before {
    content: "\e9e5";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .snap-icon-connect_filled .path4:before {
    content: "\e9e6";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .snap-icon-connection .path1:before {
    content: "\e9e7";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-connection .path2:before {
    content: "\e9e8";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-connection_dual .path1:before {
    content: "\e9e9";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-connection_dual .path2:before {
    content: "\e9ea";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-connection_dual .path3:before {
    content: "\e9eb";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-cookie:before {
    content: "\e9ec";
    color: #e6eaf0;
  }
  .snap-icon-cookie_dual .path1:before {
    content: "\e9ed";
    color: rgb(230, 234, 240);
  }
  .snap-icon-cookie_dual .path2:before {
    content: "\e9ee";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-cookie_dual .path3:before {
    content: "\e9ef";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-cookie_dual .path4:before {
    content: "\e9f0";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-cookie_dual .path5:before {
    content: "\e9f1";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-cookie_dual .path6:before {
    content: "\e9f2";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-cookie_dual .path7:before {
    content: "\e9f3";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-cookie_dual .path8:before {
    content: "\e9f4";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-cookie_dual .path9:before {
    content: "\e9f5";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-cookie_dual .path10:before {
    content: "\e9f6";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-cookie_dual .path11:before {
    content: "\e9f7";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-cookie_dual .path12:before {
    content: "\e9f8";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-cookie_dual .path13:before {
    content: "\e9f9";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-cookie_dual .path14:before {
    content: "\e9fa";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-cookie_dual .path15:before {
    content: "\e9fb";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-copy:before {
    content: "\e9fc";
    color: #e6eaf0;
  }
  .snap-icon-copy_dual .path1:before {
    content: "\e9fd";
    color: rgb(230, 234, 240);
  }
  .snap-icon-copy_dual .path2:before {
    content: "\e9fe";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-create_get_variable:before {
    content: "\e9ff";
    color: #e6eaf0;
  }
  .snap-icon-create_get_variable_dual .path1:before {
    content: "\ea00";
    color: rgb(230, 234, 240);
  }
  .snap-icon-create_get_variable_dual .path2:before {
    content: "\ea01";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-create_get_variable_dual .path3:before {
    content: "\ea02";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-create_get_variable_dual .path4:before {
    content: "\ea03";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-create_get_variable_dual .path5:before {
    content: "\ea04";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-create_get_variable_dual .path6:before {
    content: "\ea05";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-create_set_variable:before {
    content: "\ea06";
    color: #e6eaf0;
  }
  .snap-icon-create_set_variable_dual .path1:before {
    content: "\ea07";
    color: rgb(230, 234, 240);
  }
  .snap-icon-create_set_variable_dual .path2:before {
    content: "\ea08";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-create_set_variable_dual .path3:before {
    content: "\ea09";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-create_set_variable_dual .path4:before {
    content: "\ea0a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-create_set_variable_dual .path5:before {
    content: "\ea0b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-create_set_variable_dual .path6:before {
    content: "\ea0c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-create_set_variable_dual .path7:before {
    content: "\ea0d";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-crop:before {
    content: "\ea0e";
    color: #e6eaf0;
  }
  .snap-icon-csv:before {
    content: "\ea0f";
    color: #e6eaf0;
  }
  .snap-icon-csv_dual .path1:before {
    content: "\ea10";
    color: rgb(230, 234, 240);
  }
  .snap-icon-csv_dual .path2:before {
    content: "\ea11";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-csv_dual .path3:before {
    content: "\ea12";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-csv_dual .path4:before {
    content: "\ea13";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-data_connection:before {
    content: "\ea14";
    color: #e6eaf0;
  }
  .snap-icon-data_connection_dual .path1:before {
    content: "\ea15";
    color: rgb(230, 234, 240);
  }
  .snap-icon-data_connection_dual .path2:before {
    content: "\ea16";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-data_connection_dual .path3:before {
    content: "\ea17";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-data_export:before {
    content: "\ea18";
    color: #e6eaf0;
  }
  .snap-icon-data_export_dual .path1:before {
    content: "\ea19";
    color: rgb(56, 132, 255);
  }
  .snap-icon-data_export_dual .path2:before {
    content: "\ea1a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-data_link:before {
    content: "\ea1b";
    color: #e6eaf0;
  }
  .snap-icon-data_source_large:before {
    content: "\ea1c";
    color: #e6eaf0;
  }
  .snap-icon-date_picker:before {
    content: "\ea1d";
    color: #e6eaf0;
  }
  .snap-icon-date_picker_dual .path1:before {
    content: "\ea1e";
    color: rgb(230, 234, 240);
  }
  .snap-icon-date_picker_dual .path2:before {
    content: "\ea1f";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-datetime:before {
    content: "\ea20";
    color: #e6eaf0;
  }
  .snap-icon-datetime_dual .path1:before {
    content: "\ea21";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-datetime_dual .path2:before {
    content: "\ea22";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-datetime_dual .path3:before {
    content: "\ea23";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-datetime_dual .path4:before {
    content: "\ea24";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-deactivate .path1:before {
    content: "\ea25";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-deactivate .path2:before {
    content: "\ea26";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-deactivate .path3:before {
    content: "\ea27";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-deactivate_color .path1:before {
    content: "\ea28";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-deactivate_color .path2:before {
    content: "\ea29";
    margin-left: -1em;
    color: rgb(224, 102, 81);
  }
  .snap-icon-deactivate_color .path3:before {
    content: "\ea2a";
    margin-left: -1em;
    color: rgb(224, 102, 81);
  }
  .snap-icon-deactivate_dual .path1:before {
    content: "\ea2b";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-deactivate_dual .path2:before {
    content: "\ea2c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-deactivate_dual .path3:before {
    content: "\ea2d";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-debug .path1:before {
    content: "\ea2e";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-debug .path2:before {
    content: "\ea2f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-debug_dual .path1:before {
    content: "\ea30";
    color: rgb(56, 132, 255);
  }
  .snap-icon-debug_dual .path2:before {
    content: "\ea31";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-delete:before {
    content: "\ea32";
    color: #e6eaf0;
  }
  .snap-icon-delete_dual .path1:before {
    content: "\ea33";
    color: rgb(56, 132, 255);
  }
  .snap-icon-delete_dual .path2:before {
    content: "\ea34";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-delete_dual .path3:before {
    content: "\ea35";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-delete_dual .path4:before {
    content: "\ea36";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-device:before {
    content: "\ea37";
    color: #e6eaf0;
  }
  .snap-icon-device_android:before {
    content: "\ea38";
    color: #e6eaf0;
  }
  .snap-icon-device_dual .path1:before {
    content: "\ea39";
    color: rgb(230, 234, 240);
  }
  .snap-icon-device_dual .path2:before {
    content: "\ea3a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-device_dual .path3:before {
    content: "\ea3b";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-device_ios:before {
    content: "\ea3c";
    color: #e6eaf0;
  }
  .snap-icon-device_rotation:before {
    content: "\ea3d";
    color: #e6eaf0;
  }
  .snap-icon-device_rotation_dual .path1:before {
    content: "\ea3e";
    color: rgb(230, 234, 240);
  }
  .snap-icon-device_rotation_dual .path2:before {
    content: "\ea3f";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-device_rotation_dual .path3:before {
    content: "\ea40";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-device_windows:before {
    content: "\ea41";
    color: #e6eaf0;
  }
  .snap-icon-digit .path1:before {
    content: "\ea42";
    color: rgb(230, 234, 240);
  }
  .snap-icon-digit .path2:before {
    content: "\ea43";
    margin-left: -1em;
    color: rgb(34, 38, 43);
  }
  .snap-icon-document:before {
    content: "\ea44";
    color: #e6eaf0;
  }
  .snap-icon-document_dual .path1:before {
    content: "\ea45";
    color: rgb(230, 234, 240);
  }
  .snap-icon-document_dual .path2:before {
    content: "\ea46";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-document_dual .path3:before {
    content: "\ea47";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-document_dual .path4:before {
    content: "\ea48";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-document_dual .path5:before {
    content: "\ea49";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-donut_chart:before {
    content: "\ea4a";
    color: #e6eaf0;
  }
  .snap-icon-donut_chart_dual .path1:before {
    content: "\ea4b";
    color: rgb(56, 132, 255);
  }
  .snap-icon-donut_chart_dual .path2:before {
    content: "\ea4c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-double_preset_1:before {
    content: "\ea4d";
    color: #e6eaf0;
  }
  .snap-icon-double_preset_2:before {
    content: "\ea4e";
    color: #e6eaf0;
  }
  .snap-icon-double_preset_3:before {
    content: "\ea4f";
    color: #e6eaf0;
  }
  .snap-icon-double_preset_4:before {
    content: "\ea50";
    color: #e6eaf0;
  }
  .snap-icon-download:before {
    content: "\ea51";
    color: #e6eaf0;
  }
  .snap-icon-download_dual .path1:before {
    content: "\ea52";
    color: rgb(230, 234, 240);
  }
  .snap-icon-download_dual .path2:before {
    content: "\ea53";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-download_dual .path3:before {
    content: "\ea54";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-draft:before {
    content: "\ea55";
    color: #e6eaf0;
  }
  .snap-icon-draft_dual .path1:before {
    content: "\ea56";
    color: rgb(230, 234, 240);
  }
  .snap-icon-draft_dual .path2:before {
    content: "\ea57";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-drag_vertical:before {
    content: "\ea58";
    color: #e6eaf0;
  }
  .snap-icon-draggable:before {
    content: "\ea59";
    color: #e6eaf0;
  }
  .snap-icon-dropdown:before {
    content: "\ea5a";
    color: #e6eaf0;
  }
  .snap-icon-dropdown_dual .path1:before {
    content: "\ea5b";
    color: rgb(230, 234, 240);
  }
  .snap-icon-dropdown_dual .path2:before {
    content: "\ea5c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-dropdown_dual .path3:before {
    content: "\ea5d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-dropdown_dual .path4:before {
    content: "\ea5e";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-dropdown_dual .path5:before {
    content: "\ea5f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-dropdown_dual .path6:before {
    content: "\ea60";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-dropdown_dual .path7:before {
    content: "\ea61";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-dropdown_dual .path8:before {
    content: "\ea62";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-dropdown_dual .path9:before {
    content: "\ea63";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-dropdown_dual .path10:before {
    content: "\ea64";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-dropdown_dual .path11:before {
    content: "\ea65";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-dropdown_dual .path12:before {
    content: "\ea66";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-dropdown_dual .path13:before {
    content: "\ea67";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-dynamic_users:before {
    content: "\ea68";
    color: #e6eaf0;
  }
  .snap-icon-dynamic_users_dual .path1:before {
    content: "\ea69";
    color: rgb(230, 234, 240);
  }
  .snap-icon-dynamic_users_dual .path2:before {
    content: "\ea6a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-dynamic_users_dual .path3:before {
    content: "\ea6b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-dynamic_users_dual .path4:before {
    content: "\ea6c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-dynamic_users_dual .path5:before {
    content: "\ea6d";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-dynamic_users_dual .path6:before {
    content: "\ea6e";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-dynamic_users_dual .path7:before {
    content: "\ea6f";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-dynamic_users_dual .path8:before {
    content: "\ea70";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-edit:before {
    content: "\ea71";
    color: #e6eaf0;
  }
  .snap-icon-edit_dual .path1:before {
    content: "\ea72";
    color: rgb(230, 234, 240);
  }
  .snap-icon-edit_dual .path2:before {
    content: "\ea73";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-edit_user:before {
    content: "\ea74";
    color: #e6eaf0;
  }
  .snap-icon-edit_user_dual .path1:before {
    content: "\ea75";
    color: rgb(230, 234, 240);
  }
  .snap-icon-edit_user_dual .path2:before {
    content: "\ea76";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-Empty-illustration .path1:before {
    content: "\ea77";
    color: rgb(89, 101, 115);
  }
  .snap-icon-Empty-illustration .path2:before {
    content: "\ea78";
    margin-left: -1em;
    color: rgb(230, 234, 240);
    opacity: 0.1;
  }
  .snap-icon-Empty-illustration .path3:before {
    content: "\ea79";
    margin-left: -1em;
    color: rgb(89, 101, 115);
  }
  .snap-icon-Empty-illustration .path4:before {
    content: "\ea7a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
    opacity: 0.17;
  }
  .snap-icon-Empty-illustration .path5:before {
    content: "\ea7b";
    margin-left: -1em;
    color: rgb(89, 101, 115);
  }
  .snap-icon-Empty-illustration .path6:before {
    content: "\ea7c";
    margin-left: -1em;
    color: rgb(89, 101, 115);
  }
  .snap-icon-Empty-illustration .path7:before {
    content: "\ea7d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
    opacity: 0.0500;
  }
  .snap-icon-Empty-illustration .path8:before {
    content: "\ea7e";
    margin-left: -1em;
    color: rgb(89, 101, 115);
  }
  .snap-icon-Empty-illustration .path9:before {
    content: "\ea7f";
    margin-left: -1em;
    color: rgb(89, 101, 115);
  }
  .snap-icon-Empty-illustration .path10:before {
    content: "\ea80";
    margin-left: -1em;
    color: rgb(230, 234, 240);
    opacity: 0.0700;
  }
  .snap-icon-Empty-illustration .path11:before {
    content: "\ea81";
    margin-left: -1em;
    color: rgb(230, 234, 240);
    opacity: 0.1;
  }
  .snap-icon-Empty-illustration .path12:before {
    content: "\ea82";
    margin-left: -1em;
    color: rgb(230, 234, 240);
    opacity: 0.1;
  }
  .snap-icon-Empty-illustration .path13:before {
    content: "\ea83";
    margin-left: -1em;
    color: rgb(89, 101, 115);
  }
  .snap-icon-Empty-illustration .path14:before {
    content: "\ea84";
    margin-left: -1em;
    color: rgb(89, 101, 115);
  }
  .snap-icon-Empty-illustration .path15:before {
    content: "\ea85";
    margin-left: -1em;
    color: rgb(230, 234, 240);
    opacity: 0.0700;
  }
  .snap-icon-enroll:before {
    content: "\ea86";
    color: #e6eaf0;
  }
  .snap-icon-enroll_dual .path1:before {
    content: "\ea87";
    color: rgb(230, 234, 240);
  }
  .snap-icon-enroll_dual .path2:before {
    content: "\ea88";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-erase .path1:before {
    content: "\ea89";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-erase .path2:before {
    content: "\ea8a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-erase .path3:before {
    content: "\ea8b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-erase_dual .path1:before {
    content: "\ea8c";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-erase_dual .path2:before {
    content: "\ea8d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-erase_dual .path3:before {
    content: "\ea8e";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-error:before {
    content: "\ea8f";
    color: #e6eaf0;
  }
  .snap-icon-error_filled_color .path1:before {
    content: "\ea90";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-error_filled_color .path2:before {
    content: "\ea91";
    margin-left: -1em;
    color: rgb(224, 102, 81);
  }
  .snap-icon-Expand-categories:before {
    content: "\ea92";
    color: #e6eaf0;
  }
  .snap-icon-export:before {
    content: "\ea93";
    color: #e6eaf0;
  }
  .snap-icon-export_dual .path1:before {
    content: "\ea94";
    color: rgb(230, 234, 240);
  }
  .snap-icon-export_dual .path2:before {
    content: "\ea95";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-ext_link .path1:before {
    content: "\ea96";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-ext_link .path2:before {
    content: "\ea97";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-ext_link .path3:before {
    content: "\ea98";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-ext_link_dual .path1:before {
    content: "\ea99";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-ext_link_dual .path2:before {
    content: "\ea9a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-ext_link_dual .path3:before {
    content: "\ea9b";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-external_data:before {
    content: "\ea9c";
    color: #e6eaf0;
  }
  .snap-icon-external_data_dual .path1:before {
    content: "\ea9d";
    color: rgb(230, 234, 240);
  }
  .snap-icon-external_data_dual .path2:before {
    content: "\ea9e";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-external_data_dual .path3:before {
    content: "\ea9f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-external_data_dual .path4:before {
    content: "\eaa0";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-external_data_dual .path5:before {
    content: "\eaa1";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-external_input:before {
    content: "\eaa2";
    color: #e6eaf0;
  }
  .snap-icon-external_input_dual .path1:before {
    content: "\eaa3";
    color: rgb(230, 234, 240);
  }
  .snap-icon-external_input_dual .path2:before {
    content: "\eaa4";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-feedback:before {
    content: "\eaa5";
    color: #e6eaf0;
  }
  .snap-icon-feedback_dual .path1:before {
    content: "\eaa6";
    color: rgb(56, 132, 255);
  }
  .snap-icon-feedback_dual .path2:before {
    content: "\eaa7";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-feedback_dual .path3:before {
    content: "\eaa8";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-feedback_dual .path4:before {
    content: "\eaa9";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-feedback_dual .path5:before {
    content: "\eaaa";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-feedback_dual .path6:before {
    content: "\eaab";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-field:before {
    content: "\eaac";
    color: #e6eaf0;
  }
  .snap-icon-field_dual .path1:before {
    content: "\eaad";
    color: rgb(230, 234, 240);
  }
  .snap-icon-field_dual .path2:before {
    content: "\eaae";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-fifiletype_xls:before {
    content: "\eaaf";
    color: #e6eaf0;
  }
  .snap-icon-fifiletype_xls_dual .path1:before {
    content: "\eab0";
    color: rgb(230, 234, 240);
  }
  .snap-icon-fifiletype_xls_dual .path2:before {
    content: "\eab1";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-fifiletype_xls_dual .path3:before {
    content: "\eab2";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-fifiletype_xls_dual .path4:before {
    content: "\eab3";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-file:before {
    content: "\eab4";
    color: #e6eaf0;
  }
  .snap-icon-file_upload:before {
    content: "\eab5";
    color: #e6eaf0;
  }
  .snap-icon-file_upload_dual .path1:before {
    content: "\eab6";
    color: rgb(230, 234, 240);
  }
  .snap-icon-file_upload_dual .path2:before {
    content: "\eab7";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-file_upload_dual .path3:before {
    content: "\eab8";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-filetype_xml:before {
    content: "\eab9";
    color: #e6eaf0;
  }
  .snap-icon-filetype_xml_dual .path1:before {
    content: "\eaba";
    color: rgb(230, 234, 240);
  }
  .snap-icon-filetype_xml_dual .path2:before {
    content: "\eabb";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-filetype_xml_dual .path3:before {
    content: "\eabc";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-filetype_xml_dual .path4:before {
    content: "\eabd";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-Filter:before {
    content: "\eabe";
    color: #e6eaf0;
  }
  .snap-icon-fit_to_height:before {
    content: "\eabf";
    color: #e6eaf0;
  }
  .snap-icon-fit_to_screen:before {
    content: "\eac0";
    color: #e6eaf0;
  }
  .snap-icon-fit_to_screen_dual .path1:before {
    content: "\eac1";
    color: rgb(56, 132, 255);
  }
  .snap-icon-fit_to_screen_dual .path2:before {
    content: "\eac2";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-fit_to_screen_dual .path3:before {
    content: "\eac3";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-fit_to_width:before {
    content: "\eac4";
    color: #e6eaf0;
  }
  .snap-icon-flag_filled:before {
    content: "\eac5";
    color: #e6eaf0;
  }
  .snap-icon-flag_filled_color:before {
    content: "\eac6";
    color: #5ab883;
  }
  .snap-icon-font .path1:before {
    content: "\eac7";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-font .path2:before {
    content: "\eac8";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-font .path3:before {
    content: "\eac9";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-font_dual .path1:before {
    content: "\eaca";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-font_dual .path2:before {
    content: "\eacb";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-font_dual .path3:before {
    content: "\eacc";
    margin-left: -1.0419921875em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-footer:before {
    content: "\eacd";
    color: #e6eaf0;
  }
  .snap-icon-footer_dual .path1:before {
    content: "\eace";
    color: rgb(230, 234, 240);
  }
  .snap-icon-footer_dual .path2:before {
    content: "\eacf";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-form:before {
    content: "\ead0";
    color: #e6eaf0;
  }
  .snap-icon-form_dual .path1:before {
    content: "\ead1";
    color: rgb(230, 234, 240);
  }
  .snap-icon-form_dual .path2:before {
    content: "\ead2";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-form_dual .path3:before {
    content: "\ead3";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-form_dual .path4:before {
    content: "\ead4";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-form_dual .path5:before {
    content: "\ead5";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-form_dual .path6:before {
    content: "\ead6";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-four_preset:before {
    content: "\ead7";
    color: #e6eaf0;
  }
  .snap-icon-FTP:before {
    content: "\ead8";
    color: #e6eaf0;
  }
  .snap-icon-FTP_dual .path1:before {
    content: "\ead9";
    color: rgb(230, 234, 240);
  }
  .snap-icon-FTP_dual .path2:before {
    content: "\eada";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-FTP_dual .path3:before {
    content: "\eadb";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-get_logs .path1:before {
    content: "\eadc";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-get_logs .path2:before {
    content: "\eadd";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-get_logs .path3:before {
    content: "\eade";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-get_logs .path4:before {
    content: "\eadf";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-get_logs .path5:before {
    content: "\eae0";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-get_logs .path6:before {
    content: "\eae1";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-get_logs .path7:before {
    content: "\eae2";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-get_logs_dual .path1:before {
    content: "\eae3";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-get_logs_dual .path2:before {
    content: "\eae4";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-get_logs_dual .path3:before {
    content: "\eae5";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-get_logs_dual .path4:before {
    content: "\eae6";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-get_logs_dual .path5:before {
    content: "\eae7";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-get_logs_dual .path6:before {
    content: "\eae8";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-get_logs_dual .path7:before {
    content: "\eae9";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-global_variable:before {
    content: "\eaea";
    color: #e6eaf0;
  }
  .snap-icon-global_variable_dual .path1:before {
    content: "\eaeb";
    color: rgb(56, 132, 255);
  }
  .snap-icon-global_variable_dual .path2:before {
    content: "\eaec";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-Gmail:before {
    content: "\eaed";
    color: #e6eaf0;
  }
  .snap-icon-Gmail_dual .path1:before {
    content: "\eaee";
    color: rgb(230, 234, 240);
  }
  .snap-icon-Gmail_dual .path2:before {
    content: "\eaef";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-Gmail_dual .path3:before {
    content: "\eaf0";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-google:before {
    content: "\eaf1";
    color: #e6eaf0;
  }
  .snap-icon-google_drive:before {
    content: "\eaf2";
    color: #e6eaf0;
  }
  .snap-icon-google_drive_dual .path1:before {
    content: "\eaf3";
    color: rgb(230, 234, 240);
  }
  .snap-icon-google_drive_dual .path2:before {
    content: "\eaf4";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-grid:before {
    content: "\eaf5";
    color: #e6eaf0;
  }
  .snap-icon-grid_dual .path1:before {
    content: "\eaf6";
    color: rgb(230, 234, 240);
  }
  .snap-icon-grid_dual .path2:before {
    content: "\eaf7";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-grid_dual .path3:before {
    content: "\eaf8";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-grid_view:before {
    content: "\eaf9";
    color: #e6eaf0;
  }
  .snap-icon-grid_view_dual .path1:before {
    content: "\eafa";
    color: rgb(230, 234, 240);
  }
  .snap-icon-grid_view_dual .path2:before {
    content: "\eafb";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-grid_view_dual .path3:before {
    content: "\eafc";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-grid_view_dual .path4:before {
    content: "\eafd";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-group_approval:before {
    content: "\eafe";
    color: #e6eaf0;
  }
  .snap-icon-group_approval_dual .path1:before {
    content: "\eaff";
    color: rgb(230, 234, 240);
  }
  .snap-icon-group_approval_dual .path2:before {
    content: "\eb00";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-group_approval_dual .path3:before {
    content: "\eb01";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-group_approval_dual .path4:before {
    content: "\eb02";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-group_approval_dual .path5:before {
    content: "\eb03";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-group_list:before {
    content: "\eb04";
    color: #e6eaf0;
  }
  .snap-icon-group_list_dual .path1:before {
    content: "\eb05";
    color: rgb(56, 132, 255);
  }
  .snap-icon-group_list_dual .path2:before {
    content: "\eb06";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-group_list_dual .path3:before {
    content: "\eb07";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-group_list_dual .path4:before {
    content: "\eb08";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-group_list_dual .path5:before {
    content: "\eb09";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-group_list_dual .path6:before {
    content: "\eb0a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-group_list_dual .path7:before {
    content: "\eb0b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-groups .path1:before {
    content: "\eb0c";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-groups .path2:before {
    content: "\eb0d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-groups .path3:before {
    content: "\eb0e";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-groups_dual .path1:before {
    content: "\eb0f";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-groups_dual .path2:before {
    content: "\eb10";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-groups_dual .path3:before {
    content: "\eb11";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-header:before {
    content: "\eb12";
    color: #e6eaf0;
  }
  .snap-icon-header_dual .path1:before {
    content: "\eb13";
    color: rgb(230, 234, 240);
  }
  .snap-icon-header_dual .path2:before {
    content: "\eb14";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-heart:before {
    content: "\eb15";
    color: #e6eaf0;
  }
  .snap-icon-heart_filled:before {
    content: "\eb16";
    color: #e6eaf0;
  }
  .snap-icon-help:before {
    content: "\eb17";
    color: #e6eaf0;
  }
  .snap-icon-help_dual .path1:before {
    content: "\eb18";
    color: rgb(230, 234, 240);
  }
  .snap-icon-help_dual .path2:before {
    content: "\eb19";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-help_dual .path3:before {
    content: "\eb1a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-help_dual .path4:before {
    content: "\eb1b";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-help_dual .path5:before {
    content: "\eb1c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-home_filled:before {
    content: "\eb1d";
    color: #e6eaf0;
  }
  .snap-icon-home_filled_color:before {
    content: "\eb1e";
    color: #cb69a7;
  }
  .snap-icon-horizontal_bar_chart:before {
    content: "\eb1f";
    color: #e6eaf0;
  }
  .snap-icon-horizontal_bar_chart_dual .path1:before {
    content: "\eb20";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-horizontal_bar_chart_dual .path2:before {
    content: "\eb21";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-horizontal_bar_chart_dual .path3:before {
    content: "\eb22";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-image:before {
    content: "\eb23";
    color: #e6eaf0;
  }
  .snap-icon-image_dual .path1:before {
    content: "\eb24";
    color: rgb(230, 234, 240);
  }
  .snap-icon-image_dual .path2:before {
    content: "\eb25";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-image_dual .path3:before {
    content: "\eb26";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-inbox:before {
    content: "\eb27";
    color: #e6eaf0;
  }
  .snap-icon-inbox_dual .path1:before {
    content: "\eb28";
    color: rgb(230, 234, 240);
  }
  .snap-icon-inbox_dual .path2:before {
    content: "\eb29";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-info:before {
    content: "\eb2a";
    color: #e6eaf0;
  }
  .snap-icon-info_dual .path1:before {
    content: "\eb2b";
    color: rgb(56, 132, 255);
  }
  .snap-icon-info_dual .path2:before {
    content: "\eb2c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-info_dual .path3:before {
    content: "\eb2d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-info_filled-color .path1:before {
    content: "\eb2e";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-info_filled-color .path2:before {
    content: "\eb2f";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-info_filled:before {
    content: "\eb30";
    color: #e6eaf0;
  }
  .snap-icon-inline_input:before {
    content: "\eb31";
    color: #e6eaf0;
  }
  .snap-icon-inline_input_dual .path1:before {
    content: "\eb32";
    color: rgb(230, 234, 240);
  }
  .snap-icon-inline_input_dual .path2:before {
    content: "\eb33";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-label:before {
    content: "\eb34";
    color: #e6eaf0;
  }
  .snap-icon-label_dual .path1:before {
    content: "\eb35";
    color: rgb(230, 234, 240);
  }
  .snap-icon-label_dual .path2:before {
    content: "\eb36";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-label_dual .path3:before {
    content: "\eb37";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-label_dual .path4:before {
    content: "\eb38";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-launch:before {
    content: "\eb39";
    color: #e6eaf0;
  }
  .snap-icon-launch_dual .path1:before {
    content: "\eb3a";
    color: rgb(230, 234, 240);
  }
  .snap-icon-launch_dual .path2:before {
    content: "\eb3b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-launch_dual .path3:before {
    content: "\eb3c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-layers:before {
    content: "\eb3d";
    color: #e6eaf0;
  }
  .snap-icon-layers_dual .path1:before {
    content: "\eb3e";
    color: rgb(230, 234, 240);
  }
  .snap-icon-layers_dual .path2:before {
    content: "\eb3f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-layers_dual .path3:before {
    content: "\eb40";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-layers_dual .path4:before {
    content: "\eb41";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-layout:before {
    content: "\eb42";
    color: #e6eaf0;
  }
  .snap-icon-layout_dual .path1:before {
    content: "\eb43";
    color: rgb(230, 234, 240);
  }
  .snap-icon-layout_dual .path2:before {
    content: "\eb44";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-layout_dual .path3:before {
    content: "\eb45";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-layout_dual .path4:before {
    content: "\eb46";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-line_chart .path1:before {
    content: "\eb47";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-line_chart .path2:before {
    content: "\eb48";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-line_chart_dual .path1:before {
    content: "\eb49";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-line_chart_dual .path2:before {
    content: "\eb4a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-line_chart_dual .path3:before {
    content: "\eb4b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-line_chart_dual .path4:before {
    content: "\eb4c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-line_chart_dual .path5:before {
    content: "\eb4d";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-line_chart_dual .path6:before {
    content: "\eb4e";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-link:before {
    content: "\eb4f";
    color: #e6eaf0;
  }
  .snap-icon-link_dual .path1:before {
    content: "\eb50";
    color: rgb(230, 234, 240);
  }
  .snap-icon-link_dual .path2:before {
    content: "\eb51";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-link_dual .path3:before {
    content: "\eb52";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-list:before {
    content: "\eb53";
    color: #e6eaf0;
  }
  .snap-icon-list_bulleted:before {
    content: "\eb54";
    color: #e6eaf0;
  }
  .snap-icon-list_bulleted_dual .path1:before {
    content: "\eb55";
    color: rgb(230, 234, 240);
  }
  .snap-icon-list_bulleted_dual .path2:before {
    content: "\eb56";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-list_bulleted_dual .path3:before {
    content: "\eb57";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-list_bulleted_dual .path4:before {
    content: "\eb58";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-list_dual .path1:before {
    content: "\eb59";
    color: rgb(230, 234, 240);
  }
  .snap-icon-list_dual .path2:before {
    content: "\eb5a";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-list_dual .path3:before {
    content: "\eb5b";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-list_dual .path4:before {
    content: "\eb5c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-list_dual .path5:before {
    content: "\eb5d";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-list_dual .path6:before {
    content: "\eb5e";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-list_dual .path7:before {
    content: "\eb5f";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-list_empty:before {
    content: "\eb60";
    color: #9fadbc;
  }
  .snap-icon-list_numbered:before {
    content: "\eb61";
    color: #e6eaf0;
  }
  .snap-icon-list_numbered_dual .path1:before {
    content: "\eb62";
    color: rgb(230, 234, 240);
  }
  .snap-icon-list_numbered_dual .path2:before {
    content: "\eb63";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-list_numbered_dual .path3:before {
    content: "\eb64";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-list_numbered_dual .path4:before {
    content: "\eb65";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-list2:before {
    content: "\eb66";
    color: #e6eaf0;
  }
  .snap-icon-list2_dual .path1:before {
    content: "\eb67";
    color: rgb(230, 234, 240);
  }
  .snap-icon-list2_dual .path2:before {
    content: "\eb68";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-list2_dual .path3:before {
    content: "\eb69";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-list2_dual .path4:before {
    content: "\eb6a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-list2_dual .path5:before {
    content: "\eb6b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-list2_dual .path6:before {
    content: "\eb6c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-list2_dual .path7:before {
    content: "\eb6d";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-loading:before {
    content: "\eb6e";
    color: #9fadbc;
  }
  .snap-icon-location:before {
    content: "\eb6f";
    color: #e6eaf0;
  }
  .snap-icon-location_dual .path1:before {
    content: "\eb70";
    color: rgb(230, 234, 240);
  }
  .snap-icon-location_dual .path2:before {
    content: "\eb71";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-location_dual .path3:before {
    content: "\eb72";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-location_filled:before {
    content: "\eb73";
    color: #e6eaf0;
  }
  .snap-icon-location_filled_color:before {
    content: "\eb74";
    color: #e06651;
  }
  .snap-icon-lock:before {
    content: "\eb75";
    color: #e6eaf0;
  }
  .snap-icon-lock_dual .path1:before {
    content: "\eb76";
    color: rgb(230, 234, 240);
  }
  .snap-icon-lock_dual .path2:before {
    content: "\eb77";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-lockdown:before {
    content: "\eb78";
    color: #e6eaf0;
  }
  .snap-icon-lockdown_app_icon .path1:before {
    content: "\eb79";
    color: rgb(66, 130, 94);
  }
  .snap-icon-lockdown_app_icon .path2:before {
    content: "\eb7a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-logic .path1:before {
    content: "\eb7b";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-logic .path2:before {
    content: "\eb7c";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-logic_dual .path1:before {
    content: "\eb7d";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-logic_dual .path2:before {
    content: "\eb7e";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-logic_dual .path3:before {
    content: "\eb7f";
    margin-left: -1.0419921875em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-logout:before {
    content: "\eb80";
    color: #e6eaf0;
  }
  .snap-icon-logout_dual .path1:before {
    content: "\eb81";
    color: rgb(230, 234, 240);
  }
  .snap-icon-logout_dual .path2:before {
    content: "\eb82";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-loop .path1:before {
    content: "\eb83";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-loop .path2:before {
    content: "\eb84";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-loop .path3:before {
    content: "\eb85";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-loop_dual .path1:before {
    content: "\eb86";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-loop_dual .path2:before {
    content: "\eb87";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-loop_dual .path3:before {
    content: "\eb88";
    margin-left: -1.0419921875em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-manage_environments:before {
    content: "\eb89";
    color: #e6eaf0;
  }
  .snap-icon-manage_environments_dual .path1:before {
    content: "\eb8a";
    color: rgb(230, 234, 240);
  }
  .snap-icon-manage_environments_dual .path2:before {
    content: "\eb8b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-manage_environments_dual .path3:before {
    content: "\eb8c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-manage_environments_dual .path4:before {
    content: "\eb8d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-manage_environments_dual .path5:before {
    content: "\eb8e";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-manager:before {
    content: "\eb8f";
    color: #e6eaf0;
  }
  .snap-icon-manager_dual .path1:before {
    content: "\eb90";
    color: rgb(230, 234, 240);
  }
  .snap-icon-manager_dual .path2:before {
    content: "\eb91";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-manager_dual .path3:before {
    content: "\eb92";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-map:before {
    content: "\eb93";
    color: #e6eaf0;
  }
  .snap-icon-map_dual .path1:before {
    content: "\eb94";
    color: rgb(230, 234, 240);
  }
  .snap-icon-map_dual .path2:before {
    content: "\eb95";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-map_dual .path3:before {
    content: "\eb96";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-map_dual .path4:before {
    content: "\eb97";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-margin:before {
    content: "\eb98";
    color: #e6eaf0;
  }
  .snap-icon-masked:before {
    content: "\eb99";
    color: #e6eaf0;
  }
  .snap-icon-masked_dual .path1:before {
    content: "\eb9a";
    color: rgb(230, 234, 240);
  }
  .snap-icon-masked_dual .path2:before {
    content: "\eb9b";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-masked_dual .path3:before {
    content: "\eb9c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-masked_dual .path4:before {
    content: "\eb9d";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-masked_dual .path5:before {
    content: "\eb9e";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-maths .path1:before {
    content: "\eb9f";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-maths .path2:before {
    content: "\eba0";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-maths .path3:before {
    content: "\eba1";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-maths_dual .path1:before {
    content: "\eba2";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-maths_dual .path2:before {
    content: "\eba3";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-maths_dual .path3:before {
    content: "\eba4";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-maximize:before {
    content: "\eba5";
    color: #e6eaf0;
  }
  .snap-icon-menu:before {
    content: "\eba6";
    color: #e6eaf0;
  }
  .snap-icon-menu_dual .path1:before {
    content: "\eba7";
    color: rgb(230, 234, 240);
  }
  .snap-icon-menu_dual .path2:before {
    content: "\eba8";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-menu_dual .path3:before {
    content: "\eba9";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-menu_dual .path4:before {
    content: "\ebaa";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-menu_dual .path5:before {
    content: "\ebab";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-menu_dual .path6:before {
    content: "\ebac";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-minimize:before {
    content: "\ebad";
    color: #e6eaf0;
  }
  .snap-icon-mobicontrol_filled:before {
    content: "\ebae";
  }
  .snap-icon-mobile_landscape:before {
    content: "\ebaf";
    color: #e6eaf0;
  }
  .snap-icon-mobile_landscape_dual .path1:before {
    content: "\ebb0";
    color: rgb(230, 234, 240);
  }
  .snap-icon-mobile_landscape_dual .path2:before {
    content: "\ebb1";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-mobile_protrait:before {
    content: "\ebb2";
    color: #e6eaf0;
  }
  .snap-icon-mobile_protrait_dual .path1:before {
    content: "\ebb3";
    color: rgb(230, 234, 240);
  }
  .snap-icon-mobile_protrait_dual .path2:before {
    content: "\ebb4";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-modal_popup:before {
    content: "\ebb5";
    color: #e6eaf0;
  }
  .snap-icon-modal_popup_blue:before {
    content: "\ebb6";
    color: #3884ff;
  }
  .snap-icon-modal_popup_dual .path1:before {
    content: "\ebb7";
    color: rgb(230, 234, 240);
  }
  .snap-icon-modal_popup_dual .path2:before {
    content: "\ebb8";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-more:before {
    content: "\ebb9";
  }
  .snap-icon-move_to_builder:before {
    content: "\ebba";
    color: #e6eaf0;
  }
  .snap-icon-move_to_builder_dual .path1:before {
    content: "\ebbb";
    color: rgb(56, 132, 255);
  }
  .snap-icon-move_to_builder_dual .path2:before {
    content: "\ebbc";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-move_to_group .path1:before {
    content: "\ebbd";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-move_to_group .path2:before {
    content: "\ebbe";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-move_to_group .path3:before {
    content: "\ebbf";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-move_to_group_dual .path1:before {
    content: "\ebc0";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-move_to_group_dual .path2:before {
    content: "\ebc1";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-move_to_group_dual .path3:before {
    content: "\ebc2";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-my_request:before {
    content: "\ebc3";
    color: #e6eaf0;
  }
  .snap-icon-my_request_dual .path1:before {
    content: "\ebc4";
    color: rgb(230, 234, 240);
  }
  .snap-icon-my_request_dual .path2:before {
    content: "\ebc5";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-navigator:before {
    content: "\ebc6";
    color: #e6eaf0;
  }
  .snap-icon-navigator_dual .path1:before {
    content: "\ebc7";
    color: rgb(56, 132, 255);
  }
  .snap-icon-navigator_dual .path2:before {
    content: "\ebc8";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-navigator_dual .path3:before {
    content: "\ebc9";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-navigator_dual .path4:before {
    content: "\ebca";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-navigator_dual .path5:before {
    content: "\ebcb";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-navigator_dual .path6:before {
    content: "\ebcc";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-navigator_dual .path7:before {
    content: "\ebcd";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-navigator_dual .path8:before {
    content: "\ebce";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-nfc:before {
    content: "\ebcf";
    color: #e6eaf0;
  }
  .snap-icon-nfc_dual .path1:before {
    content: "\ebd0";
    color: rgb(230, 234, 240);
  }
  .snap-icon-nfc_dual .path2:before {
    content: "\ebd1";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-nfc_dual .path3:before {
    content: "\ebd2";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-number .path1:before {
    content: "\ebd3";
    color: rgb(230, 234, 240);
  }
  .snap-icon-number .path2:before {
    content: "\ebd4";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-number .path3:before {
    content: "\ebd5";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .snap-icon-number .path4:before {
    content: "\ebd6";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-number_chart:before {
    content: "\ebd7";
  }
  .snap-icon-number_chart_dual:before {
    content: "\ebd8";
  }
  .snap-icon-number_dual .path1:before {
    content: "\ebd9";
    color: rgb(230, 234, 240);
  }
  .snap-icon-number_dual .path2:before {
    content: "\ebda";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-number_dual .path3:before {
    content: "\ebdb";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .snap-icon-number_dual .path4:before {
    content: "\ebdc";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-on_click:before {
    content: "\ebdd";
    color: #e6eaf0;
  }
  .snap-icon-on_page_load:before {
    content: "\ebde";
    color: #e6eaf0;
  }
  .snap-icon-onedrive:before {
    content: "\ebdf";
    color: #e6eaf0;
  }
  .snap-icon-onedrive_dual .path1:before {
    content: "\ebe0";
    color: rgb(230, 234, 240);
  }
  .snap-icon-onedrive_dual .path2:before {
    content: "\ebe1";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-operator_between:before {
    content: "\ebe2";
    color: #e6eaf0;
  }
  .snap-icon-operator_between_not:before {
    content: "\ebe3";
    color: #e6eaf0;
  }
  .snap-icon-operator_contains:before {
    content: "\ebe4";
    color: #e6eaf0;
  }
  .snap-icon-operator_contains_not:before {
    content: "\ebe5";
    color: #e6eaf0;
  }
  .snap-icon-operator_ends_with:before {
    content: "\ebe6";
    color: #e6eaf0;
  }
  .snap-icon-operator_ends_withnot:before {
    content: "\ebe7";
    color: #e6eaf0;
  }
  .snap-icon-operator_greater_than:before {
    content: "\ebe8";
    color: #e6eaf0;
  }
  .snap-icon-operator_greater_than_equals_to:before {
    content: "\ebe9";
    color: #e6eaf0;
  }
  .snap-icon-operator_in:before {
    content: "\ebea";
    color: #e6eaf0;
  }
  .snap-icon-operator_is:before {
    content: "\ebeb";
    color: #e6eaf0;
  }
  .snap-icon-operator_is_empty:before {
    content: "\ebec";
    color: #e6eaf0;
  }
  .snap-icon-operator_is_not:before {
    content: "\ebed";
    color: #e6eaf0;
  }
  .snap-icon-operator_is_not_empty:before {
    content: "\ebee";
    color: #e6eaf0;
  }
  .snap-icon-operator_is_not_null:before {
    content: "\ebef";
    color: #e6eaf0;
  }
  .snap-icon-operator_is_null:before {
    content: "\ebf0";
    color: #e6eaf0;
  }
  .snap-icon-operator_less_than:before {
    content: "\ebf1";
    color: #e6eaf0;
  }
  .snap-icon-operator_less_than_equals_to:before {
    content: "\ebf2";
    color: #e6eaf0;
  }
  .snap-icon-operator_not_in:before {
    content: "\ebf3";
    color: #e6eaf0;
  }
  .snap-icon-operator_preceeds:before {
    content: "\ebf4";
    color: #e6eaf0;
  }
  .snap-icon-operator_starts_with:before {
    content: "\ebf5";
    color: #e6eaf0;
  }
  .snap-icon-operator_starts_with_not:before {
    content: "\ebf6";
    color: #e6eaf0;
  }
  .snap-icon-operator_succeeds:before {
    content: "\ebf7";
    color: #e6eaf0;
  }
  .snap-icon-orientation:before {
    content: "\ebf8";
    color: #e6eaf0;
  }
  .snap-icon-orientation_dual .path1:before {
    content: "\ebf9";
    color: rgb(230, 234, 240);
  }
  .snap-icon-orientation_dual .path2:before {
    content: "\ebfa";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-padding:before {
    content: "\ebfb";
    color: #e6eaf0;
  }
  .snap-icon-paid_badge .path1:before {
    content: "\ebfc";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-paid_badge .path2:before {
    content: "\ebfd";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-paid_badge .path3:before {
    content: "\ebfe";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-paid_badge_dual .path1:before {
    content: "\ebff";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-paid_badge_dual .path2:before {
    content: "\ec00";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-paid_badge_dual .path3:before {
    content: "\ec01";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-paintbrush:before {
    content: "\ec02";
    color: #e6eaf0;
  }
  .snap-icon-paintbrush_dual .path1:before {
    content: "\ec03";
    color: rgb(230, 234, 240);
  }
  .snap-icon-paintbrush_dual .path2:before {
    content: "\ec04";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-paragraph:before {
    content: "\ec05";
    color: #e6eaf0;
  }
  .snap-icon-paragraph_dual .path1:before {
    content: "\ec06";
    color: rgb(230, 234, 240);
  }
  .snap-icon-paragraph_dual .path2:before {
    content: "\ec07";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-password .path1:before {
    content: "\ec08";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-password .path2:before {
    content: "\ec09";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-password .path3:before {
    content: "\ec0a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-password_dual .path1:before {
    content: "\ec0b";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-password_dual .path2:before {
    content: "\ec0c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-password_dual .path3:before {
    content: "\ec0d";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-password_dual .path4:before {
    content: "\ec0e";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-pdf:before {
    content: "\ec0f";
    color: #e6eaf0;
  }
  .snap-icon-pdf_dual .path1:before {
    content: "\ec10";
    color: rgb(230, 234, 240);
  }
  .snap-icon-pdf_dual .path2:before {
    content: "\ec11";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-pdf_dual .path3:before {
    content: "\ec12";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-pdf_dual .path4:before {
    content: "\ec13";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-pdf_dual .path5:before {
    content: "\ec14";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-pdf_dual .path6:before {
    content: "\ec15";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-pdf_dual .path7:before {
    content: "\ec16";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-pending_color:before {
    content: "\ec17";
    color: #cf8a23;
  }
  .snap-icon-phone:before {
    content: "\ec18";
    color: #e6eaf0;
  }
  .snap-icon-pie_chart:before {
    content: "\ec19";
    color: #e6eaf0;
  }
  .snap-icon-pie_chart_dual .path1:before {
    content: "\ec1a";
    color: rgb(230, 234, 240);
  }
  .snap-icon-pie_chart_dual .path2:before {
    content: "\ec1b";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-pin_filled .path1:before {
    content: "\ec1c";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-pin_filled .path2:before {
    content: "\ec1d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-pin_filled_color .path1:before {
    content: "\ec1e";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-pin_filled_color .path2:before {
    content: "\ec1f";
    margin-left: -1em;
    color: rgb(207, 138, 35);
  }
  .snap-icon-play_outline .path1:before {
    content: "\ec20";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-play_outline .path2:before {
    content: "\ec21";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-play_outline .path3:before {
    content: "\ec22";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-play_outline_dual .path1:before {
    content: "\ec23";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-play_outline_dual .path2:before {
    content: "\ec24";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-play_outline_dual .path3:before {
    content: "\ec25";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-popup:before {
    content: "\ec26";
    color: #e6eaf0;
  }
  .snap-icon-popup_dual .path1:before {
    content: "\ec27";
    color: rgb(230, 234, 240);
  }
  .snap-icon-popup_dual .path2:before {
    content: "\ec28";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-popup_dual .path3:before {
    content: "\ec29";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-popup_dual .path4:before {
    content: "\ec2a";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-position_center:before {
    content: "\ec2b";
    color: #e6eaf0;
  }
  .snap-icon-position_center_dual .path1:before {
    content: "\ec2c";
    color: rgb(230, 234, 240);
  }
  .snap-icon-position_center_dual .path2:before {
    content: "\ec2d";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-position_left:before {
    content: "\ec2e";
    color: #e6eaf0;
  }
  .snap-icon-position_left_dual .path1:before {
    content: "\ec2f";
    color: rgb(56, 132, 255);
  }
  .snap-icon-position_left_dual .path2:before {
    content: "\ec30";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-position_left_dual .path3:before {
    content: "\ec31";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-position_right:before {
    content: "\ec32";
    color: #e6eaf0;
  }
  .snap-icon-position_right_dual .path1:before {
    content: "\ec33";
    color: rgb(56, 132, 255);
  }
  .snap-icon-position_right_dual .path2:before {
    content: "\ec34";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-position_right_dual .path3:before {
    content: "\ec35";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-position_vertical_bottom:before {
    content: "\ec36";
    color: #e6eaf0;
  }
  .snap-icon-position_vertical_bottom_dual .path1:before {
    content: "\ec37";
    color: rgb(230, 234, 240);
  }
  .snap-icon-position_vertical_bottom_dual .path2:before {
    content: "\ec38";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-position_vertical_bottom_dual .path3:before {
    content: "\ec39";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-position_vertical_center:before {
    content: "\ec3a";
    color: #e6eaf0;
  }
  .snap-icon-position_vertical_center_dual .path1:before {
    content: "\ec3b";
    color: rgb(230, 234, 240);
  }
  .snap-icon-position_vertical_center_dual .path2:before {
    content: "\ec3c";
    margin-left: -1.0419921875em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-position_vertical_top:before {
    content: "\ec3d";
    color: #e6eaf0;
  }
  .snap-icon-position_vertical_top_dual .path1:before {
    content: "\ec3e";
    color: rgb(230, 234, 240);
  }
  .snap-icon-position_vertical_top_dual .path2:before {
    content: "\ec3f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-position_vertical_top_dual .path3:before {
    content: "\ec40";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-Postion_middle:before {
    content: "\ec41";
    color: #e6eaf0;
  }
  .snap-icon-Postion_top:before {
    content: "\ec42";
    color: #e6eaf0;
  }
  .snap-icon-prefrences:before {
    content: "\ec43";
    color: #e6eaf0;
  }
  .snap-icon-prefrences_dual .path1:before {
    content: "\ec44";
    color: rgb(230, 234, 240);
  }
  .snap-icon-prefrences_dual .path2:before {
    content: "\ec45";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-prefrences_dual .path3:before {
    content: "\ec46";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-prefrences_dual .path4:before {
    content: "\ec47";
    margin-left: -1em;
    color: none;
  }
  .snap-icon-preview:before {
    content: "\ec48";
    color: #e6eaf0;
  }
  .snap-icon-preview_dual .path1:before {
    content: "\ec49";
    color: rgb(230, 234, 240);
  }
  .snap-icon-preview_dual .path2:before {
    content: "\ec4a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-preview_dual .path3:before {
    content: "\ec4b";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-published_color:before {
    content: "\ec4c";
    color: #5ab883;
  }
  .snap-icon-QR-code:before {
    content: "\ec4d";
    color: #e6eaf0;
  }
  .snap-icon-QR-code_dual .path1:before {
    content: "\ec4e";
    color: rgb(230, 234, 240);
  }
  .snap-icon-QR-code_dual .path2:before {
    content: "\ec4f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-QR-code_dual .path3:before {
    content: "\ec50";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-QR-code_dual .path4:before {
    content: "\ec51";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-QR-code_dual .path5:before {
    content: "\ec52";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-QR-code_dual .path6:before {
    content: "\ec53";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-QR-code_dual .path7:before {
    content: "\ec54";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-QR-code_dual .path8:before {
    content: "\ec55";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-QR-code_dual .path9:before {
    content: "\ec56";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-QR-code_dual .path10:before {
    content: "\ec57";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-QR-code_dual .path11:before {
    content: "\ec58";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-QR-code_dual .path12:before {
    content: "\ec59";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-QR-code_dual .path13:before {
    content: "\ec5a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-QR-code_dual .path14:before {
    content: "\ec5b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-QR-code_dual .path15:before {
    content: "\ec5c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-QR-code_dual .path16:before {
    content: "\ec5d";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-QR-code_dual .path17:before {
    content: "\ec5e";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-QR-code_dual .path18:before {
    content: "\ec5f";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-QR-code_dual .path19:before {
    content: "\ec60";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-QR-code_dual .path20:before {
    content: "\ec61";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-QR-code_dual .path21:before {
    content: "\ec62";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-QR-code_dual .path22:before {
    content: "\ec63";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-query_string:before {
    content: "\ec64";
    color: #e6eaf0;
  }
  .snap-icon-query_string_dual .path1:before {
    content: "\ec65";
    color: rgb(230, 234, 240);
  }
  .snap-icon-query_string_dual .path2:before {
    content: "\ec66";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-query_string_dual .path3:before {
    content: "\ec67";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-query_string_dual .path4:before {
    content: "\ec68";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-radio_button:before {
    content: "\ec69";
    color: #e6eaf0;
  }
  .snap-icon-radio_button_dual .path1:before {
    content: "\ec6a";
    color: rgb(230, 234, 240);
  }
  .snap-icon-radio_button_dual .path2:before {
    content: "\ec6b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-radio_button_dual .path3:before {
    content: "\ec6c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-radio_button_outline:before {
    content: "\ec6d";
    color: #e6eaf0;
  }
  .snap-icon-radius_left_bottom:before {
    content: "\ec6e";
    color: #e6eaf0;
  }
  .snap-icon-radius_left_top:before {
    content: "\ec6f";
    color: #e6eaf0;
  }
  .snap-icon-radius_right_bottom:before {
    content: "\ec70";
    color: #e6eaf0;
  }
  .snap-icon-radius_right_top:before {
    content: "\ec71";
    color: #e6eaf0;
  }
  .snap-icon-rating:before {
    content: "\ec72";
    color: #e6eaf0;
  }
  .snap-icon-rating_dual .path1:before {
    content: "\ec73";
    color: rgb(230, 234, 240);
  }
  .snap-icon-rating_dual .path2:before {
    content: "\ec74";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-redo:before {
    content: "\ec75";
    color: #e6eaf0;
  }
  .snap-icon-redo_dual .path1:before {
    content: "\ec76";
    color: rgb(230, 234, 240);
  }
  .snap-icon-redo_dual .path2:before {
    content: "\ec77";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-refresh:before {
    content: "\ec78";
    color: #e6eaf0;
  }
  .snap-icon-remove:before {
    content: "\ec79";
    color: #e6eaf0;
  }
  .snap-icon-remove_dual .path1:before {
    content: "\ec7a";
    color: rgb(230, 234, 240);
  }
  .snap-icon-remove_dual .path2:before {
    content: "\ec7b";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-remove_user .path1:before {
    content: "\ec7c";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-remove_user .path2:before {
    content: "\ec7d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-remove_user .path3:before {
    content: "\ec7e";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-remove_user .path4:before {
    content: "\ec7f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-remove_user_dual .path1:before {
    content: "\ec80";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-remove_user_dual .path2:before {
    content: "\ec81";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-remove_user_dual .path3:before {
    content: "\ec82";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-remove_user_dual .path4:before {
    content: "\ec83";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-repeater:before {
    content: "\ec84";
    color: #e6eaf0;
  }
  .snap-icon-repeater_dual .path1:before {
    content: "\ec85";
    color: rgb(230, 234, 240);
  }
  .snap-icon-repeater_dual .path2:before {
    content: "\ec86";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-report:before {
    content: "\ec87";
    color: #e6eaf0;
  }
  .snap-icon-report_alt .path1:before {
    content: "\ec88";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-report_alt .path2:before {
    content: "\ec89";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-report_alt .path3:before {
    content: "\ec8a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-report_alt .path4:before {
    content: "\ec8b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-report_alt_dual .path1:before {
    content: "\ec8c";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-report_alt_dual .path2:before {
    content: "\ec8d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-report_alt_dual .path3:before {
    content: "\ec8e";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-report_alt_dual .path4:before {
    content: "\ec8f";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-report_dual .path1:before {
    content: "\ec90";
    color: rgb(56, 132, 255);
  }
  .snap-icon-report_dual .path2:before {
    content: "\ec91";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-report_dual .path3:before {
    content: "\ec92";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-report_dual .path4:before {
    content: "\ec93";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-report_loading:before {
    content: "\ec94";
    color: #9fadbc;
  }
  .snap-icon-rest_services:before {
    content: "\ec95";
    color: #e6eaf0;
  }
  .snap-icon-rest_services_dual .path1:before {
    content: "\ec96";
    color: rgb(56, 132, 255);
  }
  .snap-icon-rest_services_dual .path2:before {
    content: "\ec97";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-rest_services_dual .path3:before {
    content: "\ec98";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-rest_services_dual .path4:before {
    content: "\ec99";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-rest_services_dual .path5:before {
    content: "\ec9a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-roles_administration .path1:before {
    content: "\ec9b";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-roles_administration .path2:before {
    content: "\ec9c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-roles_administration .path3:before {
    content: "\ec9d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-roles_administration .path4:before {
    content: "\ec9e";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-roles_administration .path5:before {
    content: "\ec9f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-roles_administration .path6:before {
    content: "\eca0";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-roles_administration .path7:before {
    content: "\eca1";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-roles_administration_dual .path1:before {
    content: "\eca2";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-roles_administration_dual .path2:before {
    content: "\eca3";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-roles_administration_dual .path3:before {
    content: "\eca4";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-roles_administration_dual .path4:before {
    content: "\eca5";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-roles_administration_dual .path5:before {
    content: "\eca6";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-roles_administration_dual .path6:before {
    content: "\eca7";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-roles_administration_dual .path7:before {
    content: "\eca8";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-rotate_left:before {
    content: "\eca9";
    color: #e6eaf0;
  }
  .snap-icon-rotate_left_dual .path1:before {
    content: "\ecaa";
    color: rgb(230, 234, 240);
  }
  .snap-icon-rotate_left_dual .path2:before {
    content: "\ecab";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-rotate_left_dual .path3:before {
    content: "\ecac";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-rotate_left_dual .path4:before {
    content: "\ecad";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-rotate_left_dual .path5:before {
    content: "\ecae";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-rotate_left_dual .path6:before {
    content: "\ecaf";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-rotate_left_dual .path7:before {
    content: "\ecb0";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-rotate_left_dual .path8:before {
    content: "\ecb1";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-rotate_left_dual .path9:before {
    content: "\ecb2";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-rotate_left_dual .path10:before {
    content: "\ecb3";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-rotate_left_dual .path11:before {
    content: "\ecb4";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-rotate_right:before {
    content: "\ecb5";
    color: #e6eaf0;
  }
  .snap-icon-rotate_right_dual .path1:before {
    content: "\ecb6";
    color: rgb(230, 234, 240);
  }
  .snap-icon-rotate_right_dual .path2:before {
    content: "\ecb7";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-rotate_right_dual .path3:before {
    content: "\ecb8";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-rotate_right_dual .path4:before {
    content: "\ecb9";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-rotate_right_dual .path5:before {
    content: "\ecba";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-rotate_right_dual .path6:before {
    content: "\ecbb";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-rotate_right_dual .path7:before {
    content: "\ecbc";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-rotate_right_dual .path8:before {
    content: "\ecbd";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-rotate_right_dual .path9:before {
    content: "\ecbe";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-rotate_right_dual .path10:before {
    content: "\ecbf";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-rotate_right_dual .path11:before {
    content: "\ecc0";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-Salesforce:before {
    content: "\ecc1";
    color: #fff;
  }
  .snap-icon-save:before {
    content: "\ecc2";
    color: #e6eaf0;
  }
  .snap-icon-save_dual .path1:before {
    content: "\ecc3";
    color: rgb(56, 132, 255);
  }
  .snap-icon-save_dual .path2:before {
    content: "\ecc4";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-script:before {
    content: "\ecc5";
    color: #e6eaf0;
  }
  .snap-icon-script_dual .path1:before {
    content: "\ecc6";
    color: rgb(230, 234, 240);
  }
  .snap-icon-script_dual .path2:before {
    content: "\ecc7";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-script_dual .path3:before {
    content: "\ecc8";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-search:before {
    content: "\ecc9";
    color: #e6eaf0;
  }
  .snap-icon-search_dual .path1:before {
    content: "\ecca";
    color: rgb(230, 234, 240);
  }
  .snap-icon-search_dual .path2:before {
    content: "\eccb";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-search_report:before {
    content: "\eccc";
    color: #e6eaf0;
  }
  .snap-icon-section:before {
    content: "\eccd";
    color: #e6eaf0;
  }
  .snap-icon-section_dual .path1:before {
    content: "\ecce";
    color: rgb(230, 234, 240);
  }
  .snap-icon-section_dual .path2:before {
    content: "\eccf";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-send .path1:before {
    content: "\ecd0";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-send .path2:before {
    content: "\ecd1";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-send_dual .path1:before {
    content: "\ecd2";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-send_dual .path2:before {
    content: "\ecd3";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-send_dual .path3:before {
    content: "\ecd4";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-server_side_script:before {
    content: "\ecd5";
    color: #e6eaf0;
  }
  .snap-icon-server_side_script_dual .path1:before {
    content: "\ecd6";
    color: rgb(230, 234, 240);
  }
  .snap-icon-server_side_script_dual .path2:before {
    content: "\ecd7";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-server_side_script_dual .path3:before {
    content: "\ecd8";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-set_status:before {
    content: "\ecd9";
    color: #e6eaf0;
  }
  .snap-icon-set_status_dual .path1:before {
    content: "\ecda";
    color: rgb(56, 132, 255);
  }
  .snap-icon-set_status_dual .path2:before {
    content: "\ecdb";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-set_status_dual .path3:before {
    content: "\ecdc";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-set_value:before {
    content: "\ecdd";
    color: #e6eaf0;
  }
  .snap-icon-set_value_dual .path1:before {
    content: "\ecde";
    color: rgb(230, 234, 240);
  }
  .snap-icon-set_value_dual .path2:before {
    content: "\ecdf";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-set_value_dual .path3:before {
    content: "\ece0";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-share:before {
    content: "\ece1";
    color: #e6eaf0;
  }
  .snap-icon-share_dual .path1:before {
    content: "\ece2";
    color: rgb(230, 234, 240);
  }
  .snap-icon-share_dual .path2:before {
    content: "\ece3";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-share_dual .path3:before {
    content: "\ece4";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-sharepoint .path1:before {
    content: "\ece5";
    color: rgb(230, 234, 240);
  }
  .snap-icon-sharepoint .path2:before {
    content: "\ece6";
    margin-left: -1em;
    color: rgb(22, 25, 29);
  }
  .snap-icon-sharepoint .path3:before {
    content: "\ece7";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-sharepoint .path4:before {
    content: "\ece8";
    margin-left: -1em;
    color: rgb(22, 25, 29);
  }
  .snap-icon-sharepoint .path5:before {
    content: "\ece9";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-sharepoint .path6:before {
    content: "\ecea";
    margin-left: -1em;
    color: rgb(22, 25, 29);
  }
  .snap-icon-sharepoint .path7:before {
    content: "\eceb";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-sharepoint .path8:before {
    content: "\ecec";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-sharepoint_dual .path1:before {
    content: "\eced";
    color: rgb(56, 132, 255);
  }
  .snap-icon-sharepoint_dual .path2:before {
    content: "\ecee";
    margin-left: -1em;
    color: rgb(22, 25, 29);
  }
  .snap-icon-sharepoint_dual .path3:before {
    content: "\ecef";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-sharepoint_dual .path4:before {
    content: "\ecf0";
    margin-left: -1em;
    color: rgb(22, 25, 29);
  }
  .snap-icon-sharepoint_dual .path5:before {
    content: "\ecf1";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-sharepoint_dual .path6:before {
    content: "\ecf2";
    margin-left: -1em;
    color: rgb(22, 25, 29);
  }
  .snap-icon-sharepoint_dual .path7:before {
    content: "\ecf3";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-sharepoint_dual .path8:before {
    content: "\ecf4";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-sheet:before {
    content: "\ecf5";
    color: #e6eaf0;
  }
  .snap-icon-sheet_dual .path1:before {
    content: "\ecf6";
    color: rgb(230, 234, 240);
  }
  .snap-icon-sheet_dual .path2:before {
    content: "\ecf7";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-signature:before {
    content: "\ecf8";
    color: #e6eaf0;
  }
  .snap-icon-signature_dual .path1:before {
    content: "\ecf9";
    color: rgb(230, 234, 240);
  }
  .snap-icon-signature_dual .path2:before {
    content: "\ecfa";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-single_present:before {
    content: "\ecfb";
    color: #e6eaf0;
  }
  .snap-icon-size_large:before {
    content: "\ecfc";
    color: #e6eaf0;
  }
  .snap-icon-size_medium:before {
    content: "\ecfd";
    color: #e6eaf0;
  }
  .snap-icon-size_small:before {
    content: "\ecfe";
    color: #e6eaf0;
  }
  .snap-icon-skin_add:before {
    content: "\ecff";
    color: #e6eaf0;
  }
  .snap-icon-skin_add_dual .path1:before {
    content: "\ed00";
    color: rgb(230, 234, 240);
  }
  .snap-icon-skin_add_dual .path2:before {
    content: "\ed01";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-smiley:before {
    content: "\ed02";
    color: #e6eaf0;
  }
  .snap-icon-smiley_filled:before {
    content: "\ed03";
    color: #e6eaf0;
  }
  .snap-icon-SMS:before {
    content: "\ed04";
    color: #e6eaf0;
  }
  .snap-icon-SMS_dual .path1:before {
    content: "\ed05";
    color: rgb(230, 234, 240);
  }
  .snap-icon-SMS_dual .path2:before {
    content: "\ed06";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-SMS_dual .path3:before {
    content: "\ed07";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-Snap_IDE .path1:before {
    content: "\ed08";
    color: rgb(69, 78, 89);
  }
  .snap-icon-Snap_IDE .path2:before {
    content: "\ed09";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .snap-icon-Snap_IDE .path3:before {
    content: "\ed0a";
    margin-left: -1em;
    color: rgb(255, 83, 0);
  }
  .snap-icon-Snap_logo_app_listing .path1:before {
    content: "\ed0b";
    color: rgb(69, 78, 89);
  }
  .snap-icon-Snap_logo_app_listing .path2:before {
    content: "\ed0c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .snap-icon-Snap_logo_app_listing .path3:before {
    content: "\ed0d";
    margin-left: -1em;
    color: rgb(255, 83, 0);
  }
  .snap-icon-soti_assist:before {
    content: "\ed0e";
    color: #e6eaf0;
  }
  .snap-icon-SOTI_Connect:before {
    content: "\ed0f";
    color: #e6eaf0;
  }
  .snap-icon-SOTI_identity:before {
    content: "\ed10";
    color: #e6eaf0;
  }
  .snap-icon-SOTI_identity_accent .path1:before {
    content: "\ed11";
    color: rgb(116, 69, 227);
  }
  .snap-icon-SOTI_identity_accent .path2:before {
    content: "\ed12";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-SOTI_Mobicontrol:before {
    content: "\ed13";
    color: #e6eaf0;
  }
  .snap-icon-soti_one:before {
    content: "\ed14";
    color: #e6eaf0;
  }
  .snap-icon-soti_one_dual .path1:before {
    content: "\ed15";
    color: rgb(230, 234, 240);
  }
  .snap-icon-soti_one_dual .path2:before {
    content: "\ed16";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-soti_one_dual .path3:before {
    content: "\ed17";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-soti_one_dual .path4:before {
    content: "\ed18";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-soti_one_dual .path5:before {
    content: "\ed19";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-SOTI_XSIGHT:before {
    content: "\ed1a";
    color: #e6eaf0;
  }
  .snap-icon-SOTI_XSIGHT_dual .path1:before {
    content: "\ed1b";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-SOTI_XSIGHT_dual .path2:before {
    content: "\ed1c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-SOTI_XSIGHT_dual .path3:before {
    content: "\ed1d";
    margin-left: -1em;
    color: rgb(215, 0, 0);
  }
  .snap-icon-SOTI_XSIGHT_dual .path4:before {
    content: "\ed1e";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-sound:before {
    content: "\ed1f";
    color: #e6eaf0;
  }
  .snap-icon-sound_dual .path1:before {
    content: "\ed20";
    color: rgb(230, 234, 240);
  }
  .snap-icon-sound_dual .path2:before {
    content: "\ed21";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-splash:before {
    content: "\ed22";
    color: #e6eaf0;
  }
  .snap-icon-splash_dual .path1:before {
    content: "\ed23";
    color: rgb(230, 234, 240);
  }
  .snap-icon-splash_dual .path2:before {
    content: "\ed24";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-standard_app .path1:before {
    content: "\ed25";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-standard_app .path2:before {
    content: "\ed26";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-standard_app .path3:before {
    content: "\ed27";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-standard_app .path4:before {
    content: "\ed28";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-standard_app .path5:before {
    content: "\ed29";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-standard_app_dual .path1:before {
    content: "\ed2a";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-standard_app_dual .path2:before {
    content: "\ed2b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-standard_app_dual .path3:before {
    content: "\ed2c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-standard_app_dual .path4:before {
    content: "\ed2d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-standard_app_dual .path5:before {
    content: "\ed2e";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-star:before {
    content: "\ed2f";
    color: #e6eaf0;
  }
  .snap-icon-star_filled:before {
    content: "\ed30";
    color: #e6eaf0;
  }
  .snap-icon-storage:before {
    content: "\ed31";
    color: #e6eaf0;
  }
  .snap-icon-storage_dual .path1:before {
    content: "\ed32";
    color: rgb(230, 234, 240);
  }
  .snap-icon-storage_dual .path2:before {
    content: "\ed33";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-storage_dual .path3:before {
    content: "\ed34";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-storage_dual .path4:before {
    content: "\ed35";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-storage_dual .path5:before {
    content: "\ed36";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-storage_dual .path6:before {
    content: "\ed37";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-sucess_filled_color .path1:before {
    content: "\ed38";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-sucess_filled_color .path2:before {
    content: "\ed39";
    margin-left: -1em;
    color: rgb(90, 184, 131);
  }
  .snap-icon-summary:before {
    content: "\ed3a";
    color: #e6eaf0;
  }
  .snap-icon-summary_dual .path1:before {
    content: "\ed3b";
    color: rgb(230, 234, 240);
  }
  .snap-icon-summary_dual .path2:before {
    content: "\ed3c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-summary_dual .path3:before {
    content: "\ed3d";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-summary_dual .path4:before {
    content: "\ed3e";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-summary_dual .path5:before {
    content: "\ed3f";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-summary_dual .path6:before {
    content: "\ed40";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-summary_dual .path7:before {
    content: "\ed41";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-switch:before {
    content: "\ed42";
    color: #e6eaf0;
  }
  .snap-icon-switch_dual .path1:before {
    content: "\ed43";
    color: rgb(230, 234, 240);
  }
  .snap-icon-switch_dual .path2:before {
    content: "\ed44";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-sync_device:before {
    content: "\ed45";
    color: #e6eaf0;
  }
  .snap-icon-sync_device_dual .path1:before {
    content: "\ed46";
    color: rgb(56, 132, 255);
  }
  .snap-icon-sync_device_dual .path2:before {
    content: "\ed47";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-sync_device_dual .path3:before {
    content: "\ed48";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-sync_device_dual .path4:before {
    content: "\ed49";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-table:before {
    content: "\ed4a";
    color: #e6eaf0;
  }
  .snap-icon-table_dual .path1:before {
    content: "\ed4b";
    color: rgb(230, 234, 240);
  }
  .snap-icon-table_dual .path2:before {
    content: "\ed4c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-tablet_landscape:before {
    content: "\ed4d";
    color: #e6eaf0;
  }
  .snap-icon-tablet_landscape_dual .path1:before {
    content: "\ed4e";
    color: rgb(230, 234, 240);
  }
  .snap-icon-tablet_landscape_dual .path2:before {
    content: "\ed4f";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-tablet_protrait:before {
    content: "\ed50";
    color: #e6eaf0;
  }
  .snap-icon-tablet_protrait_dual .path1:before {
    content: "\ed51";
    color: rgb(230, 234, 240);
  }
  .snap-icon-tablet_protrait_dual .path2:before {
    content: "\ed52";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-target .path1:before {
    content: "\ed53";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-target .path2:before {
    content: "\ed54";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-target_dual .path1:before {
    content: "\ed55";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-target_dual .path2:before {
    content: "\ed56";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-target_dual .path3:before {
    content: "\ed57";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-target_dual .path4:before {
    content: "\ed58";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-target_dual .path5:before {
    content: "\ed59";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-target_dual .path6:before {
    content: "\ed5a";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-text .path1:before {
    content: "\ed5b";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-text .path2:before {
    content: "\ed5c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_bold:before {
    content: "\ed5d";
    color: #e6eaf0;
  }
  .snap-icon-text_bold_dual .path1:before {
    content: "\ed5e";
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_bold_dual .path2:before {
    content: "\ed5f";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-text_collapsed:before {
    content: "\ed60";
    color: #e6eaf0;
  }
  .snap-icon-text_collapsed_dual .path1:before {
    content: "\ed61";
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_collapsed_dual .path2:before {
    content: "\ed62";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-text_color:before {
    content: "\ed63";
    color: #e6eaf0;
  }
  .snap-icon-text_color_dual .path1:before {
    content: "\ed64";
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_color_dual .path2:before {
    content: "\ed65";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-text_dual .path1:before {
    content: "\ed66";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-text_dual .path2:before {
    content: "\ed67";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_dual .path3:before {
    content: "\ed68";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-text_highlight:before {
    content: "\ed69";
    color: #e6eaf0;
  }
  .snap-icon-text_highlight_dual .path1:before {
    content: "\ed6a";
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_highlight_dual .path2:before {
    content: "\ed6b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_highlight_dual .path3:before {
    content: "\ed6c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-text_italic:before {
    content: "\ed6d";
    color: #e6eaf0;
  }
  .snap-icon-text_italic_dual .path1:before {
    content: "\ed6e";
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_italic_dual .path2:before {
    content: "\ed6f";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-text_line_spacing:before {
    content: "\ed70";
    color: #e6eaf0;
  }
  .snap-icon-text_line_spacing_dual .path1:before {
    content: "\ed71";
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_line_spacing_dual .path2:before {
    content: "\ed72";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_line_spacing_dual .path3:before {
    content: "\ed73";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_line_spacing_dual .path4:before {
    content: "\ed74";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_line_spacing_dual .path5:before {
    content: "\ed75";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-text_line_spacing_dual .path6:before {
    content: "\ed76";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-text_small_caps:before {
    content: "\ed77";
    color: #e6eaf0;
  }
  .snap-icon-text_small_caps_dual .path1:before {
    content: "\ed78";
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_small_caps_dual .path2:before {
    content: "\ed79";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-text_subscript:before {
    content: "\ed7a";
    color: #e6eaf0;
  }
  .snap-icon-text_subscript_alt:before {
    content: "\ed7b";
    color: #e6eaf0;
  }
  .snap-icon-text_subscript_alt_dual .path1:before {
    content: "\ed7c";
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_subscript_alt_dual .path2:before {
    content: "\ed7d";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-text_subscript_dual .path1:before {
    content: "\ed7e";
    color: rgb(230, 234, 240);
  }
  .snap-icon-text_subscript_dual .path2:before {
    content: "\ed7f";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-text_underline:before {
    content: "\ed80";
    color: #e6eaf0;
  }
  .snap-icon-text_underline_dual .path1:before {
    content: "\ed81";
    color: rgb(56, 132, 255);
  }
  .snap-icon-text_underline_dual .path2:before {
    content: "\ed82";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-textalign_center:before {
    content: "\ed83";
    color: #e6eaf0;
  }
  .snap-icon-textalign_center_dual .path1:before {
    content: "\ed84";
    color: rgb(230, 234, 240);
  }
  .snap-icon-textalign_center_dual .path2:before {
    content: "\ed85";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-textalign_center_dual .path3:before {
    content: "\ed86";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-textalign_center_dual .path4:before {
    content: "\ed87";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-textalign_justify:before {
    content: "\ed88";
    color: #e6eaf0;
  }
  .snap-icon-textalign_justify_dual .path1:before {
    content: "\ed89";
    color: rgb(230, 234, 240);
  }
  .snap-icon-textalign_justify_dual .path2:before {
    content: "\ed8a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-textalign_justify_dual .path3:before {
    content: "\ed8b";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-textalign_justify_dual .path4:before {
    content: "\ed8c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-textalign_left:before {
    content: "\ed8d";
    color: #e6eaf0;
  }
  .snap-icon-textalign_left_dual .path1:before {
    content: "\ed8e";
    color: rgb(230, 234, 240);
  }
  .snap-icon-textalign_left_dual .path2:before {
    content: "\ed8f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-textalign_left_dual .path3:before {
    content: "\ed90";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-textalign_left_dual .path4:before {
    content: "\ed91";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-textalign_right:before {
    content: "\ed92";
    color: #e6eaf0;
  }
  .snap-icon-textalign_right_dual .path1:before {
    content: "\ed93";
    color: rgb(230, 234, 240);
  }
  .snap-icon-textalign_right_dual .path2:before {
    content: "\ed94";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-textalign_right_dual .path3:before {
    content: "\ed95";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-textalign_right_dual .path4:before {
    content: "\ed96";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-textbox:before {
    content: "\ed97";
    color: #e6eaf0;
  }
  .snap-icon-textbox_dual .path1:before {
    content: "\ed98";
    color: rgb(230, 234, 240);
  }
  .snap-icon-textbox_dual .path2:before {
    content: "\ed99";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-theme .path1:before {
    content: "\ed9a";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-theme .path2:before {
    content: "\ed9b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-theme .path3:before {
    content: "\ed9c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-theme .path4:before {
    content: "\ed9d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-theme .path5:before {
    content: "\ed9e";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-theme .path6:before {
    content: "\ed9f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-theme .path7:before {
    content: "\eda0";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-theme_dual .path1:before {
    content: "\eda1";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-theme_dual .path2:before {
    content: "\eda2";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-theme_dual .path3:before {
    content: "\eda3";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-theme_dual .path4:before {
    content: "\eda4";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-theme_dual .path5:before {
    content: "\eda5";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-theme_dual .path6:before {
    content: "\eda6";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-theme_dual .path7:before {
    content: "\eda7";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-three_preset_1:before {
    content: "\eda8";
    color: #e6eaf0;
  }
  .snap-icon-three_preset_2:before {
    content: "\eda9";
    color: #e6eaf0;
  }
  .snap-icon-three_preset_3:before {
    content: "\edaa";
    color: #e6eaf0;
  }
  .snap-icon-three_preset_4:before {
    content: "\edab";
    color: #e6eaf0;
  }
  .snap-icon-three_preset_5:before {
    content: "\edac";
    color: #e6eaf0;
  }
  .snap-icon-three_preset_6:before {
    content: "\edad";
    color: #e6eaf0;
  }
  .snap-icon-three_preset_7:before {
    content: "\edae";
    color: #e6eaf0;
  }
  .snap-icon-three_preset_8:before {
    content: "\edaf";
    color: #e6eaf0;
  }
  .snap-icon-three_preset_9:before {
    content: "\edb0";
    color: #e6eaf0;
  }
  .snap-icon-thumbs_up:before {
    content: "\edb1";
    color: #e6eaf0;
  }
  .snap-icon-thumbs_up_filled:before {
    content: "\edb2";
    color: #e6eaf0;
  }
  .snap-icon-time_picker:before {
    content: "\edb3";
    color: #e6eaf0;
  }
  .snap-icon-time_picker_dual .path1:before {
    content: "\edb4";
    color: rgb(230, 234, 240);
  }
  .snap-icon-time_picker_dual .path2:before {
    content: "\edb5";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-timer .path1:before {
    content: "\edb6";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-timer .path2:before {
    content: "\edb7";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-timer .path3:before {
    content: "\edb8";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-timer .path4:before {
    content: "\edb9";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-timer_dual .path1:before {
    content: "\edba";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-timer_dual .path2:before {
    content: "\edbb";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-timer_dual .path3:before {
    content: "\edbc";
    margin-left: -1.0419921875em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-timer_dual .path4:before {
    content: "\edbd";
    margin-left: -1.0419921875em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-timer-ide-icon .path1:before {
    content: "\edbe";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-timer-ide-icon .path2:before {
    content: "\edbf";
    margin-left: -1em;
    color: rgb(9, 36, 66);
  }
  .snap-icon-timer-ide-icon .path3:before {
    content: "\edc0";
    margin-left: -1em;
    color: rgb(9, 36, 66);
  }
  .snap-icon-timer-ide-icon .path4:before {
    content: "\edc1";
    margin-left: -1em;
    color: rgb(9, 36, 66);
  }
  .snap-icon-track_request:before {
    content: "\edc2";
    color: #e6eaf0;
  }
  .snap-icon-track_request_dual .path1:before {
    content: "\edc3";
    color: rgb(230, 234, 240);
  }
  .snap-icon-track_request_dual .path2:before {
    content: "\edc4";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-unchecked:before {
    content: "\edc5";
    color: #e6eaf0;
  }
  .snap-icon-undo:before {
    content: "\edc6";
    color: #e6eaf0;
  }
  .snap-icon-undo_dual .path1:before {
    content: "\edc7";
    color: rgb(230, 234, 240);
  }
  .snap-icon-undo_dual .path2:before {
    content: "\edc8";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-unenroll .path1:before {
    content: "\edc9";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-unenroll .path2:before {
    content: "\edca";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-unenroll .path3:before {
    content: "\edcb";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-unenroll_color .path1:before {
    content: "\edcc";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-unenroll_color .path2:before {
    content: "\edcd";
    margin-left: -1em;
    color: rgb(224, 102, 81);
  }
  .snap-icon-unenroll_color .path3:before {
    content: "\edce";
    margin-left: -1em;
    color: rgb(224, 102, 81);
  }
  .snap-icon-unenroll_dual .path1:before {
    content: "\edcf";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-unenroll_dual .path2:before {
    content: "\edd0";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-unenroll_dual .path3:before {
    content: "\edd1";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-unlink:before {
    content: "\edd2";
    color: #e6eaf0;
  }
  .snap-icon-unlink_dual .path1:before {
    content: "\edd3";
    color: rgb(230, 234, 240);
  }
  .snap-icon-unlink_dual .path2:before {
    content: "\edd4";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-unlink_dual .path3:before {
    content: "\edd5";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-unlink_dual .path4:before {
    content: "\edd6";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-unlink_dual .path5:before {
    content: "\edd7";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-unlink_dual .path6:before {
    content: "\edd8";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-unlink_dual .path7:before {
    content: "\edd9";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-unlink_dual .path8:before {
    content: "\edda";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-unlocked:before {
    content: "\eddb";
    color: #e6eaf0;
  }
  .snap-icon-user:before {
    content: "\eddc";
    color: #e6eaf0;
  }
  .snap-icon-user_approval:before {
    content: "\eddd";
    color: #e6eaf0;
  }
  .snap-icon-user_approval_dual .path1:before {
    content: "\edde";
    color: rgb(230, 234, 240);
  }
  .snap-icon-user_approval_dual .path2:before {
    content: "\eddf";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-user_approval_dual .path3:before {
    content: "\ede0";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-user_avatar:before {
    content: "\ede1";
    color: #e6eaf0;
  }
  .snap-icon-user_avatar_dual .path1:before {
    content: "\ede2";
    color: rgb(56, 132, 255);
  }
  .snap-icon-user_avatar_dual .path2:before {
    content: "\ede3";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-user_dual .path1:before {
    content: "\ede4";
    color: rgb(230, 234, 240);
  }
  .snap-icon-user_dual .path2:before {
    content: "\ede5";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-user_dual .path3:before {
    content: "\ede6";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-user_initial_select .path1:before {
    content: "\ede7";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-user_initial_select .path2:before {
    content: "\ede8";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-user_initial_select .path3:before {
    content: "\ede9";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-user_initial_selected .path1:before {
    content: "\edea";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-user_initial_selected .path2:before {
    content: "\edeb";
    margin-left: -1em;
    color: rgb(44, 51, 58);
  }
  .snap-icon-user_initial_selected .path3:before {
    content: "\edec";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-user_list:before {
    content: "\eded";
    color: #e6eaf0;
  }
  .snap-icon-user_list_dual .path1:before {
    content: "\edee";
    color: rgb(230, 234, 240);
  }
  .snap-icon-user_list_dual .path2:before {
    content: "\edef";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-user_list_dual .path3:before {
    content: "\edf0";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-user_list_dual .path4:before {
    content: "\edf1";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-user_list_dual .path5:before {
    content: "\edf2";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-users_empty_large:before {
    content: "\edf3";
    color: #e6eaf0;
  }
  .snap-icon-video:before {
    content: "\edf4";
    color: #e6eaf0;
  }
  .snap-icon-video_dual .path1:before {
    content: "\edf5";
    color: rgb(230, 234, 240);
  }
  .snap-icon-video_dual .path2:before {
    content: "\edf6";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-video_dual .path3:before {
    content: "\edf7";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-video_tutorial:before {
    content: "\edf8";
    color: #e6eaf0;
  }
  .snap-icon-video_tutorial_dual .path1:before {
    content: "\edf9";
    color: rgb(56, 132, 255);
  }
  .snap-icon-video_tutorial_dual .path2:before {
    content: "\edfa";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-view:before {
    content: "\edfb";
    color: #e6eaf0;
  }
  .snap-icon-view_logs:before {
    content: "\edfc";
    color: #e6eaf0;
  }
  .snap-icon-view_logs_dual .path1:before {
    content: "\edfd";
    color: rgb(230, 234, 240);
  }
  .snap-icon-view_logs_dual .path2:before {
    content: "\edfe";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-view_logs_dual .path3:before {
    content: "\edff";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-view_logs_dual .path4:before {
    content: "\ee00";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-view_logs_dual .path5:before {
    content: "\ee01";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-view_logs_dual .path6:before {
    content: "\ee02";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-view_off:before {
    content: "\ee03";
    color: #e6eaf0;
  }
  .snap-icon-warning_active .path1:before {
    content: "\ee04";
    color: rgb(230, 234, 240);
  }
  .snap-icon-warning_active .path2:before {
    content: "\ee05";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-warning_active .path3:before {
    content: "\ee06";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-warning_active .path4:before {
    content: "\ee07";
    margin-left: -1em;
    color: rgb(232, 122, 102);
  }
  .snap-icon-warning_active_dual .path1:before {
    content: "\ee08";
    color: rgb(230, 234, 240);
  }
  .snap-icon-warning_active_dual .path2:before {
    content: "\ee09";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-warning_active_dual .path3:before {
    content: "\ee0a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-warning_active_dual .path4:before {
    content: "\ee0b";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-warning_active_dual .path5:before {
    content: "\ee0c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-warning_active_dual .path6:before {
    content: "\ee0d";
    margin-left: -1em;
    color: rgb(232, 122, 102);
  }
  .snap-icon-warning_alt_filled:before {
    content: "\ee0e";
    color: #e6eaf0;
  }
  .snap-icon-warning_default:before {
    content: "\ee0f";
    color: #e6eaf0;
  }
  .snap-icon-warning_default_dual .path1:before {
    content: "\ee10";
    color: rgb(230, 234, 240);
  }
  .snap-icon-warning_default_dual .path2:before {
    content: "\ee11";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-warning_default_dual .path3:before {
    content: "\ee12";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-warning_default_dual .path4:before {
    content: "\ee13";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-warning_default_dual .path5:before {
    content: "\ee14";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-warning_filled:before {
    content: "\ee15";
    color: #e6eaf0;
  }
  .snap-icon-warning_filled_color .path1:before {
    content: "\ee16";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-warning_filled_color .path2:before {
    content: "\ee17";
    margin-left: -1em;
    color: rgb(207, 138, 35);
  }
  .snap-icon-warning_filled_color .path3:before {
    content: "\ee18";
    margin-left: -1em;
    color: rgb(207, 138, 35);
  }
  .snap-icon-webhook:before {
    content: "\ee19";
    color: #e6eaf0;
  }
  .snap-icon-webhook_dual .path1:before {
    content: "\ee1a";
    color: rgb(230, 234, 240);
  }
  .snap-icon-webhook_dual .path2:before {
    content: "\ee1b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-webhook_dual .path3:before {
    content: "\ee1c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-webhook_dual .path4:before {
    content: "\ee1d";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-webpage:before {
    content: "\ee1e";
    color: #e6eaf0;
  }
  .snap-icon-webpage_dual .path1:before {
    content: "\ee1f";
    color: rgb(56, 132, 255);
  }
  .snap-icon-webpage_dual .path2:before {
    content: "\ee20";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-webpage_dual .path3:before {
    content: "\ee21";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-webpage_dual .path4:before {
    content: "\ee22";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-webpage_dual .path5:before {
    content: "\ee23";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-wf_expand:before {
    content: "\ee24";
    color: #e6eaf0;
  }
  .snap-icon-wf_expand_dual .path1:before {
    content: "\ee25";
    color: rgb(56, 132, 255);
  }
  .snap-icon-wf_expand_dual .path2:before {
    content: "\ee26";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-widget_value .path1:before {
    content: "\ee27";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-widget_value .path2:before {
    content: "\ee28";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-widget_value .path3:before {
    content: "\ee29";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-widget_value .path4:before {
    content: "\ee2a";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-widget_value .path5:before {
    content: "\ee2b";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-widget_value .path6:before {
    content: "\ee2c";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-widget_value .path7:before {
    content: "\ee2d";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-widget_value_dual .path1:before {
    content: "\ee2e";
    color: rgb(255, 255, 255);
    opacity: 0.0100;
  }
  .snap-icon-widget_value_dual .path2:before {
    content: "\ee2f";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-widget_value_dual .path3:before {
    content: "\ee30";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-widget_value_dual .path4:before {
    content: "\ee31";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-widget_value_dual .path5:before {
    content: "\ee32";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-widget_value_dual .path6:before {
    content: "\ee33";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-widget_value_dual .path7:before {
    content: "\ee34";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-work_filled:before {
    content: "\ee35";
    color: #e6eaf0;
  }
  .snap-icon-work_filled_color:before {
    content: "\ee36";
    color: #5eafc1;
  }
  .snap-icon-workflow:before {
    content: "\ee37";
    color: #e6eaf0;
  }
  .snap-icon-workflow_dual .path1:before {
    content: "\ee38";
    color: rgb(230, 234, 240);
  }
  .snap-icon-workflow_dual .path2:before {
    content: "\ee39";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-youtube:before {
    content: "\ee3a";
    color: #e6eaf0;
  }
  .snap-icon-youtube_dual .path1:before {
    content: "\ee3b";
    color: rgb(230, 234, 240);
  }
  .snap-icon-youtube_dual .path2:before {
    content: "\ee3c";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-Zapier:before {
    content: "\ee3d";
    color: #e6eaf0;
  }
  .snap-icon-zip:before {
    content: "\ee3e";
    color: #e6eaf0;
  }
  .snap-icon-zip_dual .path1:before {
    content: "\ee3f";
    color: rgb(230, 234, 240);
  }
  .snap-icon-zip_dual .path2:before {
    content: "\ee40";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-zip_dual .path3:before {
    content: "\ee41";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-zip_dual .path4:before {
    content: "\ee42";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  .snap-icon-zoom_in:before {
    content: "\ee43";
    color: #e6eaf0;
  }
  .snap-icon-zoom_in_dual .path1:before {
    content: "\ee44";
    color: rgb(56, 132, 255);
  }
  .snap-icon-zoom_in_dual .path2:before {
    content: "\ee45";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-Zoom_out:before {
    content: "\ee46";
    color: #e6eaf0;
  }
  .snap-icon-Zoom_out_dual .path1:before {
    content: "\ee47";
    color: rgb(230, 234, 240);
  }
  .snap-icon-Zoom_out_dual .path2:before {
    content: "\ee48";
    margin-left: -1em;
    color: rgb(230, 234, 240);
  }
  .snap-icon-Zoom_out_dual .path3:before {
    content: "\ee49";
    margin-left: -1em;
    color: rgb(56, 132, 255);
  }
  
}
  