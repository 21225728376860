
.toolboxTooltip {
  transform: translateY(20%);
  position: absolute;
  background-color: #16191D;
  color: #E6EAF0;
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, sans-serif;
  font-size: 8pt;
  padding:5px;
  left:34px;
  border-radius: 4px;
  padding:0px;
  height:0px;
  transition: height 0.15s ease;
  z-index: 99;
  border: 1px solid #2C333A;
  box-shadow: 4px 4px 20px 1px rgba(0, 0, 0, .15);
}
.toolboxTooltip::before {
   content: '';
   height: 8px;
   width: 8px;
   background: #16191D;
   position: absolute;
   top: 10px;
   border-top: 1px solid #2C333A;
   border-left: 1px solid #2C333A;
   margin-left: -15px;
   transform: rotate(-45deg);
 }

.toolboxTooltip.open {
  padding: 7px 10px;
  height: auto;
  transition: height 0.15s ease;
}

.actions-container {
  height: 50px;
  width: 100%;
  background: #054d7d;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 98;
}

.actions-container .btn,
.actions-container i {
  margin-right: 10px;
}

.actions-container i {
  color: white;
}

.actions #workspaceMountDiv {
  height: calc(100% - 50px);
}

.blocklyMainBackground {
  stroke-width: 0;
  fill: url(#backgroundimagepattern) !important;
}

.blocklyToolboxDiv {
  border-left: none !important;
}


.scrollbar-thumb{
  background-color: #454E59 !important;
}

.scrollbar-track{
  background-color: transparent !important;
}

.blocklyScrollbarHandle {
  fill: #454E59 !important;
}

.magnet-zone {
  pointer-events: none;
  transition: transform 200ms cubic-bezier(0.175, 0.885, 0.145, 1.32);
  position: absolute;
  /* text-align: center; */
  bottom: 10px;
  padding: 10px 20px;
  left: 100%;
  backface-visibility: hidden;
  perspective: 1000;
  /* transform: translate3d(0,0,0); */
  /* transform: translate(calc(-50% + 90px), 200%) translateZ(0); */
}

.magnet-zone.overlap .magnet {
  transform: scale(1.1) translateZ(0);
}

.touching .circle {
  transform: scale(0.9) translateZ(0);
}

.moving .magnet-zone {
  transform: translate(-113%, -30%) translateZ(0);
}

.magnet {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: 0 5px 8px rgba(0,0,0,0.3);
  position: relative;
  transform-origin: center;
  transition: transform 200ms cubic-bezier(0.175, 0.885, 0.145, 1.15);
  transform: scale(0.8) translateZ(0);
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.magnet:after {
  mask: url("../../../public/images/trash-icon.svg") no-repeat center;
  background-color: #5B6670;
  content: '';
  height: 25px;
  width: 25px;
  position: absolute;
}

.magnet.hover {
  background-color: #E32722;
}

.magnet.hover:after {
  background-color: white;
  mask: url("../../../public/images/trash-open.svg") no-repeat center;
}

.icon-save.hover {
  background-color: #E32722;
}

.icon-save.hover:after {
  background-color: white;
}

.moving-save .icon-save {
  transform: translate(226%, -90%) translateZ(0);
}

.save-region {
  display: block;
  margin-top: 100px;
}

.save-dialog {
  z-index: 100;
  margin: 0 auto;
  width: 500px;
  height: 200px;
  background: red;
  position: absolute;
  display: none;
  left: 0;
  right: 0;
  box-shadow: 5px 5px 5px #BBB;
  border: 1px solid #054d7d;
}

#blocklyDiv {
  height: 200px;
  width: 300px;
  border: 1px solid darkred;
}

.delete-block-icon {
  mask: url("./delete.svg") no-repeat center;
  background-color: #5B6670;
  content: '';
  height: 20px;
  width: 20px;
  position: absolute;
  cursor: pointer;
}
