.search_bar-container {
    position: absolute;
    width: 200px;
    height: 48px;
    background-color: #16191D !important;
    z-index: 47;
    border-right: 1pt solid #2C333A;
    border-bottom: 1pt solid #2C333A;
    padding: 8px 16px 8px 12px;
    box-sizing: border-box;
}

.search_bar-container #search-bar {
    font-family: 'SourceSansProRegular';
    font-size: 14px;
    width: calc(100% - 32px - 50px);
    background-color: #16191D !important;
    height: 30px;
    padding: 0px 12px 0px 36px !important;
    outline: none;
    color: #E6EAF0;
    border: 1px solid #2C333A;
    border-radius: 6px;
}

.search_bar-container #search-bar:focus {
    color: #E6EAF0;
    border-color: #3884FF;
}
.search_bar-container #search-bar::placeholder {
    color: #9FADBC;
}

.search_bar-container input:-internal-autofill-selected,
.search_bar-container input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px rgb(42, 43, 46) inset;
    -webkit-text-fill-color: #AAA !important;
    border: 1px solid rgb(42, 43, 46);
}

.search-category text {
    color: #AAA;
    font-size: 21px;
    font-weight: 700;
}

.search-icon-container {
    position: absolute;
    z-index: 999;
    left: 22px;
    top: 14px;
}

.search-icon-container > span:before {
    color: #9FADBC !important;
    font-size: 20px;
}

.search-box-container {
    width: calc(100% - 24px - 12px);
    height: 32px;
    border: 1px solid #2C333A;
    border-radius: 6px;
}

.expand-collapse-container {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 999;
    height: 24px;
    cursor: pointer;
}

.expand-collapse-container > span {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #3884FF;
    text-align: center;
    line-height: 24px !important;
    display: block;
    transform: rotateZ(180deg);
    transition: 0.2s ease-in-out transform;
}

.expand-collapse-container > span:before {
    color: #3884FF !important;
}

.expand-collapse-container.collapsed {
    right: 11px;
}

.expand-collapse-container.collapsed > span {
    transform: rotateZ(0deg);
}