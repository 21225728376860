#documentation {
    position: relative;
    height: 100%;
    /*min-width: 1380px;*/
    width: 100%;
}

#documentation h3{
    color:#0157aa
}
#documentation h2{
    font-size: 1.2em
}
#documentation .imageContainer img{
    border:1pt solid grey;
    margin: 10px;
    width: 100%;
}

#documentation .imageContainer{
    text-align: center;
    position:relative;
    background-color: #f9f9f9;
}

#documentation .imageContainer .img-sm {
    width: 250px;
}

#documentation .imageContainer .img-md {
    width: 280px;
}

#documentation .imageContainer .img-lg {
    width: 330px;
}

#documentation .imageContainer .img-xl {
    width: 400px;
}

#documentation .imageContainer .img-xxl {
    width: 4500px;
}

#documentation #title{
    display:block;
    position: fixed;
    height:60px;
    width:30%;
    background-color: #044d7c;
    color:white;
    margin:0;
    padding:0px;
    z-index: 9999;
}

#documentation #content{
    top:50px;
    display: flex;
    height: 100%;
}

#documentation #contents{
    top:50px;
    position: fixed;
    background-color: lightgray;
    width:30%;
    float:left;
    height:100%;
}

#documentation #material{
    top:50px;
    bottom:0px;
    /*left:240px;*/
    right:0px;
    position:absolute;
    padding: 0 20px;
    box-sizing: border-box;
    overflow:scroll;
    width:70%;
}

#documentation .section {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    width: calc(100% + 380px);
}

#documentation .img-right-align .imageContainer{
    right: -150px;
    display: flex;
}

#documentation .documentation-container > div {
    /*padding-top: 50px;*/
}
