@font-face {
    font-family: 'SourceSansProSemibold';
    src: url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.eot');
    src: url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.eot') format('embedded-opentype'),
    url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.woff') format('woff'),
    url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.ttf') format('truetype'),
    url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.svg#SourceSansProSemibold') format('svg');
}

$title-font: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;

#save-block-type-error-dialog-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(79, 79, 79, 0.9);
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

#save-block-type-error-dialog-wrapper {
    width: 800px;
    height: 550px;
    border: 4px solid #2C333A;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px
}

.save-block-type-error-dialog-header {
    position: relative;
    background: #16191D;
    color: #E6EAF0;
    text-align: center;
    height: 52px;
}

.save-block-type-error-dialog-header h4 {
    font-family: $title-font;
    font-size: 20px;
    margin-top: 0;
    line-height: 50px;
    text-align: left;
    padding-left: 1em;
}

.save-block-type-error-dialog-body {
    flex-grow: 1;
    justify-content: space-between;
    background: #16191D;
    padding: 20px;
    font-size: 16px;
    color: #E6EAF0;
    border-top: 1px solid #2C333A;
}

.save-block-type-error-dialog-body img {
    display: block;
    width: 75%;
    margin: 0 auto;
}

.save-block-type-error-dialog-body #block-type-description {
    color: #E6EAF0;
    text-align: center;
}

p#block-type-description {
    margin-bottom: 0;
}

.save-block-type-error-dialog-body #block-type-description #block-type-description-space {
    width: 6em;
    display: inline-block;
}

.save-block-type-error-dialog-footer {
    background: #16191D;
    min-height: 60px;
}

.save-block-type-error-dialog-footer .btn-OK {
    background-color: #004ECC;
    font-family: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;
    border: none;
    color: #E6EAF0;
    cursor: pointer;
    outline: none;
    padding: 5px;
    margin: 12px 18px;
    border-radius: 0.25rem;
    float: right;
    text-transform: uppercase;
    font-size: 14px;
    width: 84px;
    height: 36px;
    letter-spacing: 0.25px;
}

.save-block-type-error-dialog-footer .btn-OK:hover {
    background-color: #0C5FE4;
}

@media screen and (max-height: 600px) {
    #save-block-type-error-dialog-container {
        height: 100vh;
    }

    #save-block-type-error-dialog-wrapper {
        height: 90%;
        overflow-y: hidden;
    }

    .save-block-type-error-dialog-body {
        padding-top: 10px;
        padding-bottom: 0;
    }

    .save-block-type-error-dialog-body img {
        width: calc(130vh - 200px);
    }

    .save-block-type-error-dialog-body #block-type-description #block-type-description-space {
        width: 15vh;
    }
}

@media screen and (max-height: 500px) {
    .save-block-type-error-dialog-body {
        padding-top: 0;
        padding-bottom: 0;
    }
}

@media screen and (max-height: 450px) {
    .save-block-type-error-dialog-body img {
        width: 400px;
    }

    .save-block-type-error-dialog-body {
        overflow-y: scroll;
    }
}